import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
//import { HashLink as Link } from 'react-router-hash-link';
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

// Get Images Start
import logo from "../../assets/images/visions_logo.png";
import android from "../../assets/images/Header/android.svg";
import header_mean_icon from "../../assets/images/Header/header-mean-icon.svg";
import apple from "../../assets/images/Header/apple.svg";
import header_mern_icon from "../../assets/images/Header/header-mern-icon.svg";
import header_mevn_icon from "../../assets/images/Header/header-mevn-icon.svg";
import reactNative from "../../assets/images/Header/reactnative.svg";
import php from "../../assets/images/Header/php.svg";
import net from "../../assets/images/Header/net.svg";
import magento from "../../assets/images/Header/magento.svg";
import shopify from "../../assets/images/Header/shopify.svg";
import woocommerce from "../../assets/images/Header/woo.svg";
import opencart from "../../assets/images/Header/opencart.svg";
import header_python_icon from "../../assets/images/Header/header-python-icon.svg";
import header_awsCloud_icon from "../../assets/images/Header/header-awsCloud-icon.svg";
import server_admin_support from "../../assets/images/Header/server_admin_support.svg";
import header_linux_icon from "../../assets/images/Header/header-linux-icon.svg";
import header_bigcommerce_icon from "../../assets/images/Header/header-bigcommerce-icon.svg";
import responsive from "../../assets/images/Header/responsive.svg";
import landingpage from "../../assets/images/Header/wesite-design.svg";
import logoDesign from "../../assets/images/Header/logo_design.svg";
import bannerDesign from "../../assets/images/Header/banner_design.svg";
import brochureDesign from "../../assets/images/Header/brochure_design.svg";
import printmedia from "../../assets/images/Header/printmedia.svg";
import mobileAppIcon from "../../assets/images/Header/MobileAppIcon.svg";
import seo from "../../assets/images/Header/seo.svg";
import seo2 from "../../assets/images/Header/seo2.svg";
import socialMediaSeo from "../../assets/images/Header/social-media-seo.svg";

import header_emailmigration_icon from "../../assets/images/Header/header_emailmigration_icon.svg";
import online_reputation_icon from "../../assets/images/Header/online_reputation_icon.svg";

import codeigniter from "../../assets/images/Header/codeigniter.svg";
import drupal from "../../assets/images/Header/drupal.svg";
import lareval from "../../assets/images/Header/lareval.svg";
import vuejs from "../../assets/images/Header/vuejs.svg";
import node_js from "../../assets/images/Header/node_js.svg";
import wordpress_icon from "../../assets/images/Header/wordpress.svg";

import Company_About from "../../assets/images/Header/Company_About.svg";
import Company_Approach from "../../assets/images/Header/Company_Approach.svg";
import Company_Career from "../../assets/images/Header/Company_Career.svg";

//import Web_DevelopmentPortfolio from "../../assets/images/Header/Web_Development.svg";
//import UI_UX_DesignPortfolio from "../../assets/images/Header/UI_UX_Design.svg";
//import E_Commerce_SolutionsPortfolio from "../../assets/images/Header/E_Commerce_Solutions.svg";
//import Application_DevelopmentPortfolio from "../../assets/images/Header/Application_Development.svg";

// import online_shop from "../../assets/images/Header/online-shop.svg";
// import cross_platform_app from "../../assets/images/Header/Cross-platform-app.svg";
// import cms_dev from "../../assets/images/Header/cms-dev.svg";
// import hire_java from "../../assets/images/Header/hire_java.svg";
// import hire_mobileApp from "../../assets/images/Header/hire_mobileApp.svg";
import angular_js from "../../assets/images/Header/angular-js.svg";
// import hire_software from "../../assets/images/Header/hire_software.svg";

import facebook_logo from "../../assets/images/facebook-logo.svg";
// import twitter_logo from "../../assets/images/twitter-logo.svg";
import linkedin_logo from "../../assets/images/linkedin-logo.svg";
// Get Images End



// Custom Stylesheets Start
import "../../assets/css/Header.css";
import "../../assets/css/commonStyle.css";
// Custom Stylesheets End

//List Menu
import List from "@material-ui/core/List";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

class Header extends Component {
  state = {
    right: false,
    openServices: false,
    openDevelopers: false,
    openPortfolio: false,
    openCompany: false,
    isActive: "",
    isheaderMenu: "",
    open: false,
    openMobileServices: false,
    openMobileServicesWebDev: false,
    openMobileServicesAppDev: false,
    openMobileServicesEcoDev: false,
    openMobileServicesCmsDev: false,
    openMobileServicesGraDesign: false,
    openMobileServicesDeMarketing: false,
    openMobileHireDev: false,
    openMobileProtofolio: false,
    openMobileProtofolioWebDev: false,
    openMobileProtofolioAppDev: false,
    openMobileProtofolioEcoDev: false,
    openMobileProtofolioGraDesign: false
  };
  
  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };
  
  UNSAFE_componentWillMount() {
    this.headerActiveClass();
  }
  
  UNSAFE_componentWillReceiveProps() {
    this.headerActiveClass();
  }
  
  headerActiveClass() {
    var path = window.location.pathname;
    switch (path) {
      case "/services":
        this.getActiveClass("services", "services");
        break;
      case "/hire-developers":
        this.getActiveClass("hire-developers", "hire-developers");
        break;
      case "/portfolios":
        this.getActiveClass("portfolios", "portfolios");
        break;
      case "/services/web-development-services":
        this.getActiveClass("web-development-services", "services");
        break;
      case "/services/web-development-services/php-development":
        this.getActiveClass("php-development", "services");
        break;
      case "/services/web-development-services/mean-stack-development":
        this.getActiveClass("mean-stack-development", "services");
        break;
      case "/services/web-development-services/mern-stack-development":
        this.getActiveClass("mern-stack-development", "services");
        break;
      case "/services/web-development-services/mevn-stack-development":
        this.getActiveClass("mevn-stack-development", "services");
        break;
      case "/services/web-development-services/asp-net-development":
        this.getActiveClass("asp-net-development", "services");
        break;
      case "/services/web-development-services/python-development":
        this.getActiveClass("python-development", "services");
        break;
      case "/services/uiux-design-services":
        this.getActiveClass("graphic-design-services", "services");
        break;
      case "/services/uiux-design-services/website-redesign":
        this.getActiveClass("website-redesign", "services");
        break;
      case "/services/uiux-design-services/responsive-web-design":
        this.getActiveClass("responsive-web-design", "services");
        break;
      case "/services/uiux-design-services/logo-designing":
        this.getActiveClass("logo-designing", "services");
        break;
      case "/services/uiux-design-services/banner-designing":
        this.getActiveClass("banner-designing", "services");
        break;
      case "/services/uiux-design-services/brochure-designing":
        this.getActiveClass("brochure-designing", "services");
        break;
      // case "/services/uiux-design-services/print-media-design":
      //   this.getActiveClass("print-media-design", "services");
      //   break;
      case "/services/uiux-design-services/mobile-app-design":
        this.getActiveClass("mobile-app-design", "services");
        break;
      case "/services/ecommerce-development-services":
        this.getActiveClass("ecommerce-development-services", "services");
        break;
      case "/services/ecommerce-development-services/magento-development":
        this.getActiveClass("magento-development", "services");
        break;
      case "/services/ecommerce-development-services/woocommerce-development":
        this.getActiveClass("woocommerce-development", "services");
        break;
      case "/services/ecommerce-development-services/shopify-development":
        this.getActiveClass("shopify-development", "services");
        break;
      case "/services/ecommerce-development-services/bigcommerce-development":
        this.getActiveClass("bigcommerce-development", "services");
        break;
      case "/services/ecommerce-development-services/openCart-development":
        this.getActiveClass("openCart-development", "services");
        break;
      case "/services/app-development-services":
        this.getActiveClass("application-development-services", "services");
        break;
      case "/services/app-development-services/android-app-development":
        this.getActiveClass("android-app-development", "services");
        break;
      case "/services/app-development-services/ios-app-development":
        this.getActiveClass("ios-app-development", "services");
        break;
      case "/services/app-development-services/react-native-development":
        this.getActiveClass("react-native-development", "services");
        break;
      case "/services/server-network-admin-support-services":
        this.getActiveClass("server-network-admin-support", "services");
        break;
      case "/services/server-network-admin-support-services/server-network-administration":
        this.getActiveClass("server-network-administration", "services");
        break;
      case "/services/server-network-admin-support-services/aws-cloud-hosting":
        this.getActiveClass("aws-cloud-hosting", "services");
        break;
      case "/services/server-network-admin-support-services/linux":
        this.getActiveClass("linux", "services");
        break;
      case "/services/server-network-admin-support-services/email-migration":
        this.getActiveClass("email-migration", "services");
        break;
      case "/services/digital-marketing-services":
        this.getActiveClass("digital-marketing-services", "services");
        break;
      case "/services/digital-marketing-services/seo-services-india":
        this.getActiveClass("seo-services-india", "services");
        break;
      case "/services/digital-marketing-services/local-seo":
        this.getActiveClass("local-seo", "services");
        break;
      case "/services/digital-marketing-services/social-media-optimization":
        this.getActiveClass("social-media-optimization", "services");
        break;
      case "/services/digital-marketing-services/online-reputation-management":
        this.getActiveClass("online-reputation-management", "services");
        break;
      case "/hire-developers/hire-android-app-developers":
        this.getActiveClass("hire-android-app-developers", "hire-developers");
        break;
      case "/hire-developers/hire-angular-developers":
        this.getActiveClass("hire-angularjs-developers", "hire-developers");
        break;
      case "/hire-developers/hire-asp-net-developers":
        this.getActiveClass("hire-asp-net-developers", "hire-developers");
        break;
      case "/hire-developers/hire-codeigniter-developers":
        this.getActiveClass("hire-codeigniter-developers", "hire-developers");
        break;
      case "/hire-developers/hire-drupal-developers":
        this.getActiveClass("hire-drupal-developers", "hire-developers");
        break;
      case "/hire-developers/hire-ios-app-developers":
        this.getActiveClass("hire-ios-app-developers", "hire-developers");
        break;
      case "/hire-developers/hire-laravel-developers":
        this.getActiveClass("hire-laravel-developers", "hire-developers");
        break;
      case "/hire-developers/hire-magento-developers":
        this.getActiveClass("hire-magento-developers", "hire-developers");
        break;
      case "/hire-developers/hire-nodejs-developers":
        this.getActiveClass("hire-nodejs-developers", "hire-developers");
        break;
      case "/hire-developers/hire-php-developers":
        this.getActiveClass("hire-php-developers", "hire-developers");
        break;
      case "/hire-developers/hire-python-developers":
        this.getActiveClass("hire-python-developers", "hire-developers");
        break;
      case "/hire-developers/hire-react-native-developers":
        this.getActiveClass("hire-react-native-developers", "hire-developers");
        break;
      case "/hire-developers/hire-reactjs-developers":
        this.getActiveClass("hire-reactjs-developers", "hire-developers");
        break;
      case "/hire-developers/hire-vuejs-developers":
        this.getActiveClass("hire-vuejs-developers", "hire-developers");
        break;
      case "/hire-developers/hire-wordpress-developers":
        this.getActiveClass("hire-wordpress-developers", "hire-developers");
        break;
      case "/hire-developers/hire-web-designer":
        this.getActiveClass("hire-web-designer", "hire-developers");
        break;
      case "/portfolios/web-development":
        this.getActiveClass("web-development", "portfolios");
        break;
      case "/portfolios/web-development/php-development":
        this.getActiveClass("php_development", "portfolios");
        break;
      case "/portfolios/web-development/mean-stack-development":
        this.getActiveClass("mean_stack_development", "portfolios");
        break;
      case "/portfolios/web-development/mern-stack-development":
        this.getActiveClass("mern_stack_development", "portfolios");
        break;
      case "/portfolios/web-development/mevn-stack-development":
        this.getActiveClass("mevn_stack_development", "portfolios");
        break;
      case "/portfolios/web-development/asp-net-development":
        this.getActiveClass("asp_net_development", "portfolios");
        break;
      case "/portfolios/web-development/python-development":
        this.getActiveClass("python_development", "portfolios");
        break;
      case "/portfolios/uiux-design":
        this.getActiveClass("uiux-design", "portfolios");
        break;
      case "/portfolios/uiux-design/website-redesign":
        this.getActiveClass("website_redesign", "portfolios");
        break;
      case "/portfolios/uiux-design/responsive-web-design":
        this.getActiveClass("responsive_web_design", "portfolios");
        break;
      case "/portfolios/uiux-design/logo-designing":
        this.getActiveClass("logo_designing", "portfolios");
        break;
      case "/portfolios/uiux-design/banner-designing":
        this.getActiveClass("banner_designing", "portfolios");
        break;
      case "/portfolios/uiux-design/brochure-designing":
        this.getActiveClass("brochure_designing", "portfolios");
        break;
      case "/portfolios/uiux-design/print-media-design":
        this.getActiveClass("print_media_design", "portfolios");
        break;
      case "/portfolios/ecommerce-solutions":
        this.getActiveClass("ecommerce-solutions", "portfolios");
        break;
      case "/portfolios/ecommerce-solutions/magento-development":
        this.getActiveClass("magento_development", "portfolios");
        break;
      case "/portfolios/ecommerce-solutions/woocommerce-development":
        this.getActiveClass("woocommerce_development", "portfolios");
        break;
      case "/portfolios/ecommerce-solutions/shopify-development":
        this.getActiveClass("shopify_development", "portfolios");
        break;
      case "/portfolios/ecommerce-solutions/open-cart-development":
        this.getActiveClass("open_cart_development", "portfolios");
        break;
      case "/portfolios/ecommerce-solutions/bigcommerce-development":
        this.getActiveClass("bigcommerce_development", "portfolios");
        break;
      case "/portfolios/application-development":
        this.getActiveClass("application-development", "portfolios");
        break;
      case "/portfolios/application-development/android-app-development":
        this.getActiveClass("android_app_development", "portfolios");
        break;
      case "/portfolios/application-development/ios-app-development":
        this.getActiveClass("ios_app_development", "portfolios");
        break;
      case "/portfolios/application-development/react-native-development":
        this.getActiveClass("react_native_development", "portfolios");
        break;
      case "/about-us":
        this.getActiveClass("about-us", "company");
        break;
      case "/our-approach":
        this.getActiveClass("ourapproach", "company");
        break;
      case "/careers":
        this.getActiveClass("careers", "company");
        break;
      default:
        this.getActiveClass("", "");
        break;
    }
  }

  getActiveClass = (value, value1) => {
    this.setState({ isActive: value, isheaderMenu: value1 });
  };
  sethomePage = () => {
    this.setState({ isActive: "", isheaderMenu: "" });
  };
  handleServicesToggle = () => {
    this.setState(state => ({ openServices: !state.openServices }));
  };

  handleServicesToggle2 = () => {
    this.setState({ openServices: false });
  };

  handleDevelopersToggle = () => {
    this.setState(state => ({ openDevelopers: !state.openDevelopers }));
  };

  handleDevelopersToggle2 = () => {
    this.setState({ openDevelopers: false });
  };

  handlePortfolioToggle = () => {
    this.setState(state => ({ openPortfolio: !state.openPortfolio }));
  };

  handlePortfolioToggle2 = () => {
    this.setState({ openPortfolio: false });
  };

  handleCompanyToggle = () => {
    this.setState(state => ({ openCompany: !state.openCompany }));
  };

  handleCompanyToggle2 = () => {
    this.setState({ openCompany: false });
  };

  //For Company
  handleClick = () => {
    if (this.state.open === true) {
      this.setState({
        openMobileServices: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false,
        openMobileWebDesign: false
      });
    }
    this.setState({ open: !this.state.open });
  };

  handleClickForMobileHeader = () => {
    this.handleClick();
    this.setState({
      right: false
    });
  };

  //For Web Development
  handleClickForMobileHeaderWeb = () => {
    this.handleClickWeb();
    this.setState({
      right: false
    });
  };
  handleClickWeb = () => {
    if (this.state.openMobileServicesWebDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileServicesWebDev: !this.state.openMobileServicesWebDev
    });
  };
  handleClickServicesWeb = () => {
    this.setState({
      openMobileServicesWebDev: !this.state.openMobileServicesWebDev
    });
  };
  //Web End

  //For App Development
  handleClickForMobileHeaderApp = () => {
    this.handleClickApp();
    this.setState({
      right: false
    });
  };
  handleClickApp = () => {
    if (this.state.openMobileServicesAppDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileServicesAppDev: !this.state.openMobileServicesAppDev
    });
  };
  handleClickServicesAppDev = () => {
    this.setState({
      openMobileServicesAppDev: !this.state.openMobileServicesAppDev
    });
  };
  //App Develeopment End

  handleClickServices = () => {
    this.setState({ openMobileServices: !this.state.openMobileServices });
  };

  //For Web Design
  handleClickWebDesign = () => {
    if (this.state.openMobileWebDesign === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileWebDesign: !this.state.openMobileWebDesign
    });
  };
  handleClickForHeaderWebDesign = () => {
    this.handleClickWebDesign();
    this.setState({
      right: false
    });
  };
  handleClickServicesWebDesign = () => {
    this.setState({
      openMobileWebDesign: !this.state.openMobileWebDesign
    });
  };
  //End Web Design

  //For Ecommerce Development
  handleClickEcoDev = () => {
    if (this.state.openMobileServicesEcoDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileServicesEcoDev: !this.state.openMobileServicesEcoDev
    });
  };
  handleClickForHeaderEcoDev = () => {
    this.handleClickEcoDev();
    this.setState({
      right: false
    });
  };
  handleClickServicesEcoDev = () => {
    this.setState({
      openMobileServicesEcoDev: !this.state.openMobileServicesEcoDev
    });
  };
  //End Ecommerce Development

  //For WordPress Development
  handleClickCmsDev = () => {
    if (this.state.openMobileServicesCmsDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileServicesCmsDev: !this.state.openMobileServicesCmsDev
    });
  };
  handleClickForHeaderCmsDev = () => {
    this.handleClickCmsDev();
    this.setState({
      right: false
    });
  };
  handleClickServicesCmsDev = () => {
    this.setState({
      openMobileServicesCmsDev: !this.state.openMobileServicesCmsDev
    });
  };
  //End WordPress Development

  //For Graphic Design
  handleClickGraDesign = () => {
    if (this.state.openMobileServicesGraDesign === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileServicesGraDesign: !this.state.openMobileServicesGraDesign
    });
  };
  handleClickForHeaderGraDesign = () => {
    this.handleClickGraDesign();
    this.setState({
      right: false
    });
  };
  handleClickServicesGraDesign = () => {
    this.setState({
      openMobileServicesGraDesign: !this.state.openMobileServicesGraDesign
    });
  };
  //End Graphic Design

  //For Digital marketing
  handleClickDeMarketing = () => {
    if (this.state.openMobileServicesDeMarketing === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileServicesDeMarketing: !this.state.openMobileServicesDeMarketing
    });
  };
  handleClickForHeaderDeMarketing = () => {
    this.handleClickDeMarketing();
    this.setState({
      right: false
    });
  };
  handleClickServicesDeMarketing = () => {
    this.setState({
      openMobileServicesDeMarketing: !this.state.openMobileServicesDeMarketing
    });
  };
  //End Digital Marketing

  //For Hire Developers
  handleClickHireDev = () => {
    if (this.state.openMobileHireDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileHireDev: !this.state.openMobileHireDev
    });
  };
  handleClickForHeaderHireDev = () => {
    this.handleClickHireDev();
    this.setState({
      right: false
    });
  };
  handleClickHireDevlopers = () => {
    this.setState({
      openMobileHireDev: !this.state.openMobileHireDev
    });
  };
  //End Hire Developers

  //Start Protofolio
  handleClickProtofolio = () => {
    if (this.state.openMobileProtofolio === true) {
      this.setState({
        openMobileServices: false,
        openMobileHireDev: false,
        open: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileProtofolio: !this.state.openMobileProtofolio
    });
  };
  handleClickForHeaderProtofolio = () => {
    this.handleClickProtofolio();
    this.setState({
      right: false
    });
  };
  handleProtofolio = () => {
    this.setState({
      openMobileProtofolio: !this.state.openMobileProtofolio
    });
  };

  //For Web Development portfolio
  handleClickForMobileHeaderWeb1 = () => {
    this.handleClickWeb1();
    this.setState({
      right: false
    });
  };
  handleClickWeb1 = () => {
    if (this.state.openMobileProtofolioWebDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileProtofolioWebDev: !this.state.openMobileProtofolioWebDev
    });
  };
  handleClickServicesWeb1 = () => {
    this.setState({
      openMobileProtofolioWebDev: !this.state.openMobileProtofolioWebDev
    });
  };
  //Web End

  //For Graphic Design
  handleClickGraDesign1 = () => {
    if (this.state.openMobileProtofolioGraDesign === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileProtofolioGraDesign: !this.state.openMobileProtofolioGraDesign
    });
  };
  handleClickForHeaderGraDesign1 = () => {
    this.handleClickGraDesign1();
    this.setState({
      right: false
    });
  };
  handleClickServicesGraDesign1 = () => {
    this.setState({
      openMobileProtofolioGraDesign: !this.state.openMobileProtofolioGraDesign
    });
  };
  //End Graphic Design

  //For Ecommerce Development
  handleClickEcoDev1 = () => {
    if (this.state.openMobileProtofolioEcoDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileProtofolioEcoDev: !this.state.openMobileProtofolioEcoDev
    });
  };
  handleClickForHeaderEcoDev1 = () => {
    this.handleClickEcoDev1();
    this.setState({
      right: false
    });
  };
  handleClickServicesEcoDev1 = () => {
    this.setState({
      openMobileProtofolioEcoDev: !this.state.openMobileProtofolioEcoDev
    });
  };
  //End Ecommerce Development

  //For App Development
  handleClickForMobileHeaderApp1 = () => {
    this.handleClickApp1();
    this.setState({
      right: false
    });
  };
  handleClickApp1 = () => {
    if (this.state.openMobileProtofolioAppDev === true) {
      this.setState({
        openMobileServices: false,
        open: false,
        openMobileHireDev: false,
        openMobileProtofolio: false,
        openMobileServicesWebDev: false,
        openMobileServicesAppDev: false,
        openMobileServicesEcoDev: false,
        openMobileServicesCmsDev: false,
        openMobileServicesGraDesign: false,
        openMobileServicesDeMarketing: false,
        openMobileWebDesign: false,
        openMobileProtofolioWebDev: false,
        openMobileProtofolioAppDev: false,
        openMobileProtofolioEcoDev: false,
        openMobileProtofolioGraDesign: false
      });
    }
    this.setState({
      openMobileProtofolioAppDev: !this.state.openMobileProtofolioAppDev
    });
  };
  handleClickServicesAppDev1 = () => {
    this.setState({
      openMobileProtofolioAppDev: !this.state.openMobileProtofolioAppDev
    });
  };
  //App Develeopment End
  //End Protofolio

  render() {
    const {
      open,
      openMobileServices,
      openMobileServicesWebDev,
      openMobileServicesAppDev,
      openMobileWebDesign,
      openMobileServicesEcoDev,
      openMobileServicesGraDesign,
      openMobileServicesDeMarketing,
      openMobileHireDev,
      openMobileProtofolio,
      openMobileProtofolioWebDev,
      openMobileProtofolioAppDev,
      openMobileProtofolioEcoDev,
      openMobileProtofolioGraDesign
    } = this.state;

    const {
      openServices,
      openDevelopers,
      openPortfolio,
      openCompany
    } = this.state;

    return (
      <div className="header_main">
        <AppBar position="static" color="default">
          <Toolbar className="header_toolbar">
            <Link className="logo_titleText" to="/" onClick={this.sethomePage}>
              <img alt="Visions" title="Visions" src={logo} />
            </Link>
            <aside className="desktop_menus">
              <Hidden lgUp>
                <Drawer
                  anchor="right"
                  className="mobile_menu_wrap"
                  open={this.state.right}
                  onClose={this.toggleDrawer("right", false)}
                >
                  <div
                    tabIndex={0}
                    role="button"
                    // onClick={this.toggleDrawer("right", false)}
                    onKeyDown={this.toggleDrawer("right", false)}
                  >
                    <div className="mobile_menus">
                      <MenuList className="menu_wrap">
                        {/* For Services */}
                        <MenuList className="mobile-top-close-bar">
                          <MenuItem className="mobile_logo_toggle">
                            <Link to="/">
                              <img alt="Visions" title="Visions" src={logo} onClick={this.toggleDrawer("right", false)} />
                            </Link>
                          </MenuItem>
                          <MenuItem className="home_and_close_icon">
                            <Icon className="close_icon" onClick={this.toggleDrawer("right", false)} title="Close">close</Icon>
                          </MenuItem>
                        </MenuList>
                        <MenuList>
                          <MenuItem
                            className={
                              this.state.isActive === "about-us"
                                ? "active innerMenuItems ml-0"
                                : "innerMenuItems ml-0"
                            }
                            onClick={this.handleClickForMobileHeader}
                          >
                            <Link
                              to="/about-us"
                              onClick={() =>
                                this.getActiveClass("about-us", "company")
                              }
                              title="About Us"
                            >
                              {/* <img src={Company_About} className="menu_items_icons" alt="About Us" /> */}
                              About Us
                            </Link>
                          </MenuItem>
                        </MenuList>
                        <MenuList>
                          <MenuItem
                            className={this.state.isheaderMenu === "services" ? "active" : ""}
                          // onClick={this.handleClickServices}
                          >
                            <Link
                              to="/services"
                              aria-owns={openServices ? "menu-list-grow" : undefined}
                              aria-haspopup="true"
                              //onClick={this.handleClickForMobileHeaderWeb}
                              onClick={(event) => {
                                this.getActiveClass("", "services")
                                this.handleClickForMobileHeaderWeb()
                              }}
                              title="Services"
                            >
                              Services
                            </Link>
                            {openMobileServices ? <ExpandLess onClick={this.handleClickServices} /> : <ExpandMore onClick={this.handleClickServices} />}
                          </MenuItem>
                          <Collapse in={openMobileServices} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                              {/* For Web Development */}
                              <MenuItem
                                className={
                                  this.state.isActive === "web-development-services" ||
                                    this.state.isActive === "php-development" ||
                                    this.state.isActive === "mern-stack-development" ||
                                    this.state.isActive === "mean-stack-development" ||
                                    this.state.isActive === "mevn-stack-development" ||
                                    this.state.isActive === "asp-net-development" ||
                                    this.state.isActive === "python-stack-development"
                                    ? "active innerMenuItems cat_head_title"
                                    : "innerMenuItems cat_head_title"
                                }
                              //onClick={this.handleClickServicesWeb}
                              >
                                <Link
                                  to="/services/web-development-services"
                                  onClick={(event) => {
                                    this.getActiveClass("web-development-services", "services")
                                    this.handleClickForMobileHeaderWeb()
                                  }}
                                  title="Web Development"
                                >
                                  Web Development
                                </Link>
                                {openMobileServicesWebDev ? <ExpandLess onClick={this.handleClickServicesWeb} /> : <ExpandMore onClick={this.handleClickServicesWeb} />}
                              </MenuItem>
                              <Collapse
                                in={openMobileServicesWebDev}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "php-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb}
                                >
                                  <Link
                                    to="/services/web-development-services/php-development"
                                    onClick={() =>
                                      this.getActiveClass("php-development", "services")
                                    }
                                    title="PHP Development"
                                  >
                                    <img src={php} className="menu_items_icons" alt="PHP Development" />
                                    PHP Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "mean-stack-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb}
                                >
                                  <Link
                                    to="/services/web-development-services/mean-stack-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "mean-stack-development",
                                        "services"
                                      )
                                    }
                                    title="Mean Stack Development"
                                  >
                                    <img src={header_mean_icon} className="menu_items_icons" alt="MEAN Stack Development" />
                                    Mean Stack Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "mern-stack-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb}
                                >
                                  <Link
                                    to="/services/web-development-services/mern-stack-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "mern-stack-development",
                                        "services"
                                      )
                                    }
                                    title="Mern Stack Development"
                                  >
                                    <img src={header_mern_icon} className="menu_items_icons" alt="MERN Stack Development" />
                                    Mern Stack Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "mevn-stack-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb}
                                >
                                  <Link
                                    to="/services/web-development-services/mevn-stack-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "mevn-stack-development",
                                        "services"
                                      )
                                    }
                                    title="Mevn Stack Development"
                                  >
                                    <img src={header_mevn_icon} className="menu_items_icons" alt="Mevn Stack Development" />
                                    Mevn Stack Development
                                  </Link>
                                </MenuItem>
                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "asp-net-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb}
                                >
                                  <Link
                                    to="/services/web-development-services/asp-net-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "asp-net-development",
                                        "services"
                                      )
                                    }
                                    title="Asp.Net Development"
                                  >
                                    <img src={net} className="menu_items_icons" alt="Asp.Net Development" />
                                    Asp.Net Development
                                  </Link>
                                </MenuItem> */}
                                <MenuItem
                                  className={
                                    this.state.isActive === "python-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb}
                                >
                                  <Link
                                    to="/services/web-development-services/python-development"
                                    onClick={() =>
                                      this.getActiveClass("python-development", "services")
                                    }
                                    title="Python Development"
                                  >
                                    <img src={header_python_icon} className="menu_items_icons" alt="Python Development" />
                                    Python Development
                                  </Link>
                                </MenuItem>
                              </Collapse>

                              {/* For Graphic Design */}
                              <MenuItem
                                className={
                                  this.state.isActive === "graphic-design-services" ||
                                    this.state.isActive === "website-redesign" ||
                                    this.state.isActive === "logo-designing" ||
                                    this.state.isActive === "banner-designing" ||
                                    this.state.isActive === "brochure-designing" ||
                                    this.state.isActive === "print-media-design" ||
                                    this.state.isActive === "mobile-app-design" ||
                                    this.state.isActive === "responsive-web-design"
                                    ? "active cat_head_title innerMenuItems"
                                    : "cat_head_title innerMenuItems"
                                }
                              //onClick={this.handleClickServicesGraDesign}
                              >
                                <Link
                                  to="/services/uiux-design-services"
                                  className="cat_head_title"
                                  onClick={(event) => {
                                    this.getActiveClass("graphic-design-services", "services")
                                    this.handleClickForHeaderGraDesign()
                                  }}
                                  title="UI/UX"
                                >
                                  UI/UX
                                </Link>
                                {openMobileServicesGraDesign ? (
                                  <ExpandLess onClick={this.handleClickServicesGraDesign} />
                                ) : (
                                    <ExpandMore onClick={this.handleClickServicesGraDesign} />
                                  )}
                              </MenuItem>
                              <Collapse
                                in={openMobileServicesGraDesign}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "website-redesign"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/website-redesign"
                                    onClick={() =>
                                      this.getActiveClass("website-redesign", "services")
                                    }
                                    title="Website Redesign"
                                  >
                                    <img src={landingpage} className="menu_items_icons" alt="Website Redesign" />
                                    Website Redesign
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "responsive-web-design"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/responsive-web-design"
                                    onClick={() =>
                                      this.getActiveClass("responsive-web-design", "services")
                                    }
                                    title="Responsive Web Design"
                                  >
                                    <img src={responsive} className="menu_items_icons" alt="Responsive Web Design" />
                                    Responsive Web Design
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "logo-designing"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/logo-designing"
                                    onClick={() =>
                                      this.getActiveClass("logo-designing", "services")
                                    }
                                    title="Logo Designing"
                                  >
                                    <img src={logoDesign} className="menu_items_icons" alt="Logo Designing" />
                                    Logo Designing
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "banner-designing"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/banner-designing"
                                    onClick={() =>
                                      this.getActiveClass("banner-designing", "services")
                                    }
                                    title="Banner Designing"
                                  >
                                    <img src={bannerDesign} className="menu_items_icons" alt="Banner Designing" />
                                    Banner Designing
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "brochure-designing"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/brochure-designing"
                                    onClick={() =>
                                      this.getActiveClass("brochure-designing", "services")
                                    }
                                    title="Brochure Designing"
                                  >
                                    <img src={brochureDesign} className="menu_items_icons" alt="Brochure Designing" />
                                    Brochure Designing
                                  </Link>
                                </MenuItem>
                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "print-media-design"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/print-media-design"
                                    onClick={() =>
                                      this.getActiveClass("print-media-design", "services")
                                    }
                                    title="Print Media Design"
                                  >
                                    <img src={printmedia} className="menu_items_icons" alt="Print Media Design" />
                                    Print Media Design
                                  </Link>
                                </MenuItem> */}
                                <MenuItem
                                  className={
                                    this.state.isActive === "mobile-app-design"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign}
                                >
                                  <Link
                                    to="/services/uiux-design-services/mobile-app-design"
                                    onClick={() =>
                                      this.getActiveClass("mobile-app-design", "services")
                                    }
                                    title="Mobile App Design"
                                  >
                                    <img src={mobileAppIcon} className="menu_items_icons" alt="Mobile App Design" />
                                    Mobile App Design
                                  </Link>
                                </MenuItem>
                              </Collapse>

                              {/* For Ecommerce Development */}
                              <MenuItem
                                className={
                                  this.state.isActive === "ecommerce-development-services" ||
                                    this.state.isActive === "magento-development" ||
                                    this.state.isActive === "shopify-development" ||
                                    this.state.isActive === "woocommerce-development" ||
                                    this.state.isActive === "openCart-redesign" ||
                                    this.state.isActive === "bigcommerce-redesign"
                                    ? "active cat_head_title innerMenuItems"
                                    : "cat_head_title innerMenuItems"
                                }
                              //onClick={this.handleClickServicesEcoDev}
                              >
                                <Link
                                  to="/services/ecommerce-development-services"
                                  className="cat_head_title"
                                  onClick={(event) => {
                                    this.getActiveClass("ecommerce-development-services", "services")
                                    this.handleClickForHeaderEcoDev()
                                  }}
                                  title="E-commerce Solutions"
                                >
                                  E-commerce Solutions
                                </Link>
                                {openMobileServicesEcoDev ? (
                                  <ExpandLess onClick={this.handleClickServicesEcoDev} />
                                ) : (
                                    <ExpandMore onClick={this.handleClickServicesEcoDev} />
                                  )}
                              </MenuItem>
                              <Collapse
                                in={openMobileServicesEcoDev}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "magento-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev}
                                >
                                  <Link
                                    to="/services/ecommerce-development-services/magento-development"
                                    onClick={() =>
                                      this.getActiveClass("magento-development", "services")
                                    }
                                    title="Magento Development"
                                  >
                                    <img src={magento} className="menu_items_icons" alt="Magento Development" />
                                    Magento Development
                                  </Link>
                                </MenuItem> */}
                                <MenuItem
                                  className={
                                    this.state.isActive === "woocommerce-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev}
                                >
                                  <Link
                                    to="/services/ecommerce-development-services/woocommerce-development"
                                    onClick={() =>
                                      this.getActiveClass("woocommerce-development", "services")
                                    }
                                    title="WooCommerce Development"
                                  >
                                    <img src={woocommerce} className="menu_items_icons" alt="WooCommerce Development" />
                                    WooCommerce Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "shopify-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev}
                                >
                                  <Link
                                    to="/services/ecommerce-development-services/shopify-development"
                                    onClick={() =>
                                      this.getActiveClass("shopify-development", "services")
                                    }
                                    title="Shopify Development"
                                  >
                                    <img src={shopify} className="menu_items_icons" alt="Shopify Development" />
                                    Shopify Development
                                  </Link>
                                </MenuItem>

                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "openCart-redesign"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev}
                                >
                                  <Link
                                    to="/services/ecommerce-development-services/openCart-development"
                                    onClick={() =>
                                      this.getActiveClass("openCart-redesign", "services")
                                    }
                                    title="OpenCart Develeopment"
                                  >
                                    <img src={opencart} className="menu_items_icons" alt="OpenCart Redesign" />
                                    OpenCart Develeopment
                                  </Link>
                                </MenuItem> */}
                                <MenuItem
                                  className={
                                    this.state.isActive === "bigcommerce-redesign"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev}
                                >
                                  <Link
                                    to="/services/ecommerce-development-services/bigcommerce-development"
                                    onClick={() =>
                                      this.getActiveClass("bigcommerce-redesign", "services")
                                    }
                                    title="BigCommerce Development"
                                  >
                                    <img src={header_bigcommerce_icon} className="menu_items_icons" alt="BigCommerce Development" />
                                    BigCommerce Development
                                  </Link>
                                </MenuItem>
                              </Collapse>

                              
                              {/* For App Develeopment */}
                              <MenuItem
                                className={
                                  this.state.isActive === "application-development-services" ||
                                    this.state.isActive === "android-app-development" ||
                                    this.state.isActive === "ios-app-development" ||
                                    this.state.isActive === "react-native-development"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                              //onClick={this.handleClickServicesAppDev}
                              >
                                <Link
                                  to="/services/app-development-services"
                                  onClick={(event) => {
                                    this.getActiveClass("application-development-services", "services")
                                    this.handleClickForMobileHeaderApp()
                                  }}
                                  title="Mobile App Development"
                                >
                                  Mobile App Development
                                </Link>
                                {openMobileServicesAppDev ? <ExpandLess onClick={this.handleClickServicesAppDev} /> : <ExpandMore onClick={this.handleClickServicesAppDev} />}
                              </MenuItem>
                              <Collapse
                                in={openMobileServicesAppDev}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "android-app-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderApp}
                                >
                                  <Link
                                    to="/services/app-development-services/android-app-development"
                                    onClick={() =>
                                      this.getActiveClass("android-app-development", "services")
                                    }
                                    title="Android App Development"
                                  >
                                    <img src={android} className="menu_items_icons" alt="Android App Development" />
                                    Android App Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "ios-app-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderApp}
                                >
                                  <Link
                                    to="/services/app-development-services/ios-app-development"
                                    onClick={() =>
                                      this.getActiveClass("ios-app-development", "services")
                                    }
                                    title="iOS App Development"
                                  >
                                    <img src={apple} className="menu_items_icons" alt="iOS App Development" />
                                    iOS App Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "react-native-development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderApp}
                                >
                                  <Link
                                    to="/services/app-development-services/react-native-development"
                                    onClick={() =>
                                      this.getActiveClass("react-native-development", "services")
                                    }
                                    title="React Native Development"
                                  >
                                    <img src={reactNative} className="menu_items_icons" alt="React Native Development" />
                                    React Native Development
                                  </Link>
                                </MenuItem>
                              </Collapse>

                               {/* For Server/Network Admin Support */}
                              <MenuItem
                                className={
                                  this.state.isActive === "server-network-admin-support" ||
                                    this.state.isActive === "aws-cloud-hosting" ||
                                    this.state.isActive === "linux" ||
                                    this.state.isActive === "email-migration"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                              //onClick={this.handleClickServicesWebDesign}
                              >
                                <Link
                                  to="/services/server-network-admin-support-services"
                                  onClick={(event) => {
                                    this.getActiveClass("server-network-admin-support", "services")
                                    this.handleClickForHeaderWebDesign()
                                  }}
                                  title="Server/Network Admin Support"
                                >
                                  Server/Network Admin Support
                                </Link>
                                {openMobileWebDesign ? <ExpandLess onClick={this.handleClickServicesWebDesign} /> : <ExpandMore onClick={this.handleClickServicesWebDesign} />}
                              </MenuItem>
                              <Collapse in={openMobileWebDesign} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding />                                
                                <MenuItem
                                  className={
                                    this.state.isActive === "server-network-administration"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderWebDesign}
                                >
                                  <Link
                                    to="/services/server-network-admin-support-services/server-network-administration"
                                    onClick={() =>
                                      this.getActiveClass("server-network-administration", "services")
                                    }
                                    title="Server Network Administration"
                                  >
                                    <img src={server_admin_support} className="menu_items_icons" alt="Server Network Administration" />
                                    Server Network Administration
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "aws-cloud-hosting"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderWebDesign}
                                >
                                  <Link
                                    to="/services/server-network-admin-support-services/aws-cloud-hosting"
                                    onClick={() =>
                                      this.getActiveClass("aws-cloud-hosting", "services")
                                    }
                                    title="AWS Cloud Hosting"
                                  >
                                    <img src={header_awsCloud_icon} className="menu_items_icons" alt="AWS Cloud Hosting" />
                                    AWS Cloud Hosting
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "linux"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderWebDesign}
                                >
                                  <Link
                                    to="/services/server-network-admin-support-services/linux"
                                    onClick={() =>
                                      this.getActiveClass("linux", "services")
                                    }
                                    title="Linux"
                                  >
                                    <img src={header_linux_icon} className="menu_items_icons" alt="Linux" />
                                    Linux
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "email-migration"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderWebDesign}
                                >
                                  <Link
                                    to="/services/server-network-admin-support-services/email-migration"
                                    onClick={() =>
                                      this.getActiveClass("email-migration", "services")
                                    }
                                    title="Email Migration"
                                  >
                                    <img src={header_emailmigration_icon} className="menu_items_icons" alt="Email Migration" />
                                    Email Migration
                                  </Link>
                                </MenuItem>
                              </Collapse>

                              {/* For Digital Marketing */}
                              <MenuItem
                                className={
                                  this.state.isActive === "digital-marketing-services" ||
                                    this.state.isActive === "seo-services-india" ||
                                    this.state.isActive === "local-seo" ||
                                    this.state.isActive === "social-media-optimization" ||
                                    this.state.isActive === "online-reputation-management"
                                    ? "active cat_head_title innerMenuItems"
                                    : "cat_head_title innerMenuItems"
                                }
                              //onClick={this.handleClickServicesDeMarketing}
                              >
                                <Link
                                  to="/services/digital-marketing-services"
                                  className="cat_head_title"
                                  onClick={(event) => {
                                    this.getActiveClass("digital-marketing-services", "services")
                                    this.handleClickForHeaderDeMarketing()
                                  }}
                                  title="Digital Marketing"
                                >
                                  Digital Marketing
                                </Link>
                                {openMobileServicesDeMarketing ? (
                                  <ExpandLess onClick={this.handleClickServicesDeMarketing} />
                                ) : (
                                    <ExpandMore onClick={this.handleClickServicesDeMarketing} />
                                  )}
                              </MenuItem>
                              <Collapse
                                in={openMobileServicesDeMarketing}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "seo-services-india"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderDeMarketing}
                                >
                                  <Link
                                    to="/services/digital-marketing-services/seo-services-india"
                                    onClick={() =>
                                      this.getActiveClass("seo-services-india", "services")
                                    }
                                    title="Search Engine Optimization"
                                  >
                                    <img src={seo} className="menu_items_icons" alt="Search Engine Optimization" />
                                    Search Engine Optimization
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "local-seo"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderDeMarketing}
                                >
                                  <Link
                                    to="/services/digital-marketing-services/local-seo"
                                    onClick={() =>
                                      this.getActiveClass("local-seo", "services")
                                    }
                                    title="Local SEO"
                                  >
                                    <img src={seo2} className="menu_items_icons" alt="Local SEO" />
                                    Local SEO
                                  </Link>
                                </MenuItem>

                                <MenuItem
                                  className={
                                    this.state.isActive === "social-media-optimization"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderDeMarketing}
                                >
                                  <Link
                                    to="/services/digital-marketing-services/social-media-optimization"
                                    onClick={() =>
                                      this.getActiveClass("social-media-optimization", "services")
                                    }
                                    title="Social Media Optimization"
                                  >
                                    <img src={socialMediaSeo} className="menu_items_icons" alt="Social Media Optimization" />
                                    Social Media Optimization
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "online-reputation-management"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderDeMarketing}
                                >
                                  <Link
                                    to="/services/digital-marketing-services/online-reputation-management"
                                    onClick={() =>
                                      this.getActiveClass("online-reputation-management", "services")
                                    }
                                    title="Online Reputation Management"
                                  >
                                    <img src={online_reputation_icon} className="menu_items_icons" alt="Online Reputation Management" />
                                    Online Reputation Management
                                  </Link>
                                </MenuItem>
                              </Collapse>


                            </List>
                          </Collapse>
                        </MenuList>

                        {/* For Hire Developers */}
                        <MenuList className="d-none">
                          <MenuItem
                            className={
                              this.state.isheaderMenu === "hire-developers" ? "active" : ""
                            }
                          >
                            <Link
                              to="/hire-developers/hire-android-app-developers"
                              aria-owns={openMobileHireDev ? "menu-list-grow" : undefined}
                              aria-haspopup="true"
                              //onClick={this.handleClickForHeaderHireDev}
                              onClick={(event) => {
                                this.getActiveClass("", "hire-developers")
                                this.handleClickForHeaderHireDev()
                              }}
                              title="Hire Developers"
                            >
                              Hire Developers
                            </Link>
                            {openMobileHireDev ? <ExpandLess onClick={this.handleClickHireDevlopers} /> : <ExpandMore onClick={this.handleClickHireDevlopers} />}
                          </MenuItem>
                          <Collapse in={openMobileHireDev} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {/* Hire Android App Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-android-app-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-android-app-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-android-app-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Android App Developers"
                                >
                                  <img src={android} className="menu_items_icons" alt="Android App Developers" />
                                  Android App Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Angular Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-angularjs-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-angular-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-angularjs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Angular Developers"
                                >
                                  <img src={angular_js} className="menu_items_icons" alt="Angular Developers" />
                                  Angular Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Aspnet Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-asp-net-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-asp-net-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-asp-net-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Asp.Net Developers"
                                >
                                  <img src={net} className="menu_items_icons" alt="Asp.Net Developers" />
                                  Asp.Net Developers
                                </Link>
                              </MenuItem>

                              {/* Hire codeigniter Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-codeigniter-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-codeigniter-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-codeigniter-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="CodeIgniter Developers"
                                >
                                  <img src={codeigniter} className="menu_items_icons" alt="CodeIgniter Developers" />
                                  CodeIgniter Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Drupal Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-drupal-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-drupal-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-drupal-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Drupal Developers"
                                >
                                  <img src={drupal} className="menu_items_icons" alt="Drupal Developers" />
                                  Drupal Developers
                                </Link>
                              </MenuItem>

                              {/* Hire IOS App Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-ios-app-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-ios-app-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-ios-app-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="IOS App Developers"
                                >
                                  <img src={apple} className="menu_items_icons" alt="IOS App Developers" />
                                  IOS App Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Laravel Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-laravel-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-laravel-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-laravel-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Laravel Developers"
                                >
                                  <img src={lareval} className="menu_items_icons" alt="Laravel Developers" />
                                  Laravel Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Magento Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-magento-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-magento-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-magento-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Magento Developers"
                                >
                                  <img src={magento} className="menu_items_icons" alt="Magento Developers" />
                                  Magento Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Nodejs Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-nodejs-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-nodejs-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-nodejs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="NodeJS Developers"
                                >
                                  <img src={node_js} className="menu_items_icons" alt="NodeJS Developers" />
                                  NodeJS Developers
                                </Link>
                              </MenuItem>

                              {/* Hire PHP Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-php-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-php-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-php-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="PHP Developers"
                                >
                                  <img src={php} className="menu_items_icons" alt="PHP Developers" />
                                  PHP Developers
                                </Link>
                              </MenuItem>


                              {/* Hire Python Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-python-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-python-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-python-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Python Developers"
                                >
                                  <img src={header_python_icon} className="menu_items_icons" alt="Python Developers" />
                                  Python Developers
                                </Link>
                              </MenuItem>

                              {/* Hire React Native App Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-react-native-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-react-native-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-react-native-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="React Native App Developers"
                                >
                                  <img src={reactNative} className="menu_items_icons" alt="React Native App Developers" />
                                  React Native App Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Reactjs Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-reactjs-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-reactjs-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-reactjs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="ReactJS Developers"
                                >
                                  <img src={reactNative} className="menu_items_icons" alt="ReactJS Developers" />
                                  ReactJS Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Vuejs Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-vuejs-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-vuejs-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-vuejs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="VueJS Developers"
                                >
                                  <img src={vuejs} className="menu_items_icons" alt="VueJS Developers" />
                                  VueJS Developers
                                </Link>
                              </MenuItem>

                              {/* Hire Wordpress Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-wordpress-developers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-wordpress-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-wordpress-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Wordpress Developers"
                                >
                                  <img src={wordpress_icon} className="menu_items_icons" alt="Wordpress Developers" />
                                  Wordpress Developers
                                </Link>
                              </MenuItem>
                              
                              {/* Hire Web Designer */}
                              <MenuItem
                                className={
                                  this.state.isActive === "hire-web-designer"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderHireDev}
                              >
                                <Link
                                  to="/hire-developers/hire-web-designer"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-web-designer",
                                      "hire-developers"
                                    )
                                  }
                                  title="Web Designer"
                                >
                                  <img src={responsive} className="menu_items_icons" alt="Web Designer" />
                                  Web Designer
                                </Link>
                              </MenuItem>
                            </List>
                          </Collapse>
                        </MenuList>

                        {/* For portfolio */}
                        <MenuList>
                          <MenuItem
                            className={
                              this.state.isheaderMenu === "portfolios" ? "active" : ""
                            }
                          // onClick={this.handleProtofolio}
                          >
                            <Link
                              to="/portfolios"
                              aria-owns={openMobileProtofolio ? "menu-list-grow" : undefined}
                              aria-haspopup="true"
                              //onClick={this.handleClickForHeaderProtofolio}
                              onClick={(event) => {
                                this.getActiveClass("", "portfolios");
                                this.handleClickForHeaderProtofolio();
                              }}
                              title="Portfolios"
                            >
                              Portfolios
                            </Link>
                            {openMobileProtofolio ? <ExpandLess onClick={this.handleProtofolio} /> : <ExpandMore onClick={this.handleProtofolio} />}
                          </MenuItem>
                          <Collapse in={openMobileProtofolio} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              {/* <MenuItem
                                className={
                                  this.state.isActive === "wordpress"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolio/wordpress"
                                  onClick={() =>
                                    this.getActiveClass("wordpress", "portfolios")
                                  }
                                >
                                  Wordpress Development
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "magento"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolio/magento"
                                  onClick={() =>
                                    this.getActiveClass("magento", "portfolios")
                                  }
                                >
                                  Magento Development
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "drupal"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolio/drupal"
                                  onClick={() =>
                                    this.getActiveClass("drupal", "portfolios")
                                  }
                                >
                                  Drupal Development
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "reactjs"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolio/reactjs"
                                  onClick={() =>
                                    this.getActiveClass("reactjs", "portfolios")
                                  }
                                >
                                  ReactJs Development
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "react-native"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolio/react-native"
                                  onClick={() =>
                                    this.getActiveClass("react-native", "portfolios")
                                  }
                                >
                                  React Native Development
                                </Link>
                              </MenuItem> */}

                              {/* by sahil */}

                              {/* <MenuItem
                                className={
                                  this.state.isActive === "web-development"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolios/web-development"
                                  onClick={() =>
                                    this.getActiveClass("web-development", "portfolios")
                                  }
                                  title="Web Development"
                                >
                                  <img src={Web_DevelopmentPortfolio} className="menu_items_icons" alt="Web Development" />
                                  Web Development
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "uiux-design"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolios/uiux-design"
                                  onClick={() =>
                                    this.getActiveClass("uiux-design", "portfolios")
                                  }
                                  title="UI/UX Design"
                                >
                                  <img src={UI_UX_DesignPortfolio} className="menu_items_icons" alt="UI/UX Design" />
                                  UI/UX Design
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "ecommerce-solutions"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolios/ecommerce-solutions"
                                  onClick={() =>
                                    this.getActiveClass("ecommerce-solutions", "portfolios")
                                  }
                                  title="E-Commerce Solutions"
                                >
                                  <img src={E_Commerce_SolutionsPortfolio} className="menu_items_icons" alt="E-Commerce Solutions" />
                                  E-Commerce Solutions
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "application-development"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForHeaderProtofolio}
                              >
                                <Link
                                  to="/portfolios/application-development"
                                  onClick={() =>
                                    this.getActiveClass("application-development", "portfolios")
                                  }
                                  title="App Development"
                                >
                                  <img src={Application_DevelopmentPortfolio} className="menu_items_icons" alt="App Development" />
                                  App Development
                                </Link>
                              </MenuItem> */}
                            
                              {/* For Web Development */}
                              <MenuItem
                                className={
                                  this.state.isActive === "web-development" ||
                                    this.state.isActive === "php_development" ||
                                    this.state.isActive === "mern_stack_development" ||
                                    this.state.isActive === "mean_stack_development" ||
                                    this.state.isActive === "mevn_stack_development" ||
                                    this.state.isActive === "asp_net_development" ||
                                    this.state.isActive === "python_development"
                                    ? "active innerMenuItems cat_head_title"
                                    : "innerMenuItems cat_head_title"
                                }
                              //onClick={this.handleClickServicesWeb}
                              >
                                <Link
                                  to="/portfolios/web-development"
                                  onClick={(event) => {
                                    this.getActiveClass("web-development", "portfolios")
                                    this.handleClickForMobileHeaderWeb1()
                                  }}
                                  title="Web Development"
                                >
                                  Web Development
                                </Link>
                                {openMobileProtofolioWebDev ? <ExpandLess onClick={this.handleClickServicesWeb1} /> : <ExpandMore onClick={this.handleClickServicesWeb1} />}
                              </MenuItem>
                              <Collapse
                                in={openMobileProtofolioWebDev}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "php_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb1}
                                >
                                  <Link
                                    to="/portfolios/web-development/php-development"
                                    onClick={() =>
                                      this.getActiveClass("php_development", "portfolios")
                                    }
                                    title="PHP Development"
                                  >
                                    <img src={php} className="menu_items_icons" alt="PHP Development" />
                                    PHP Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "mean_stack_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb1}
                                >
                                  <Link
                                    to="/portfolios/web-development/mean-stack-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "mean_stack_development",
                                        "portfolios"
                                      )
                                    }
                                    title="Mean Stack Development"
                                  >
                                    <img src={header_mean_icon} className="menu_items_icons" alt="MEAN Stack Development" />
                                    Mean Stack Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "mern_stack_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb1}
                                >
                                  <Link
                                    to="/portfolios/web-development/mern-stack-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "mern_stack_development",
                                        "portfolios"
                                      )
                                    }
                                    title="Mern Stack Development"
                                  >
                                    <img src={header_mern_icon} className="menu_items_icons" alt="MERN Stack Development" />
                                    Mern Stack Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "mevn_stack_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb1}
                                >
                                  <Link
                                    to="/portfolios/web-development/mevn-stack-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "mevn_stack_development",
                                        "portfolios"
                                      )
                                    }
                                    title="Mevn Stack Development"
                                  >
                                    <img src={header_mevn_icon} className="menu_items_icons" alt="Mevn Stack Development" />
                                    Mevn Stack Development
                                  </Link>
                                </MenuItem>
                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "asp_net_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb1}
                                >
                                  <Link
                                    to="/portfolios/web-development/asp-net-development"
                                    onClick={() =>
                                      this.getActiveClass(
                                        "asp_net_development",
                                        "portfolios"
                                      )
                                    }
                                    title="Asp.Net Development"
                                  >
                                    <img src={net} className="menu_items_icons" alt="Asp.Net Development" />
                                    Asp.Net Development
                                  </Link>
                                </MenuItem> */}
                                <MenuItem
                                  className={
                                    this.state.isActive === "python_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderWeb1}
                                >
                                  <Link
                                    to="/portfolios/web-development/python-development"
                                    onClick={() =>
                                      this.getActiveClass("python_development", "portfolios")
                                    }
                                    title="Python Development"
                                  >
                                    <img src={header_python_icon} className="menu_items_icons" alt="Python Development" />
                                    Python Development
                                  </Link>
                                </MenuItem>
                              </Collapse>
                            
                              {/* For Graphic Design */}
                              <MenuItem
                                className={
                                  this.state.isActive === "uiux-design" ||
                                    this.state.isActive === "website_redesign" ||
                                    this.state.isActive === "logo_designing" ||
                                    this.state.isActive === "banner_designing" ||
                                    this.state.isActive === "brochure_designing" ||
                                    this.state.isActive === "print_media_design" ||
                                    this.state.isActive === "mobile_app_design" ||
                                    this.state.isActive === "responsive_web_design"
                                    ? "active cat_head_title innerMenuItems"
                                    : "cat_head_title innerMenuItems"
                                }
                              //onClick={this.handleClickServicesGraDesign}
                              >
                                <Link
                                  to="/portfolios/uiux-design"
                                  className="cat_head_title"
                                  onClick={(event) => {
                                    this.getActiveClass("uiux-design", "portfolios")
                                    this.handleClickForHeaderGraDesign1()
                                  }}
                                  title="UI/UX"
                                >
                                  UI/UX
                                </Link>
                                {openMobileProtofolioGraDesign ? (
                                  <ExpandLess onClick={this.handleClickServicesGraDesign1} />
                                ) : (
                                    <ExpandMore onClick={this.handleClickServicesGraDesign1} />
                                  )}
                              </MenuItem>
                              <Collapse
                                in={openMobileProtofolioGraDesign}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "website_redesign"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign1}
                                >
                                  <Link
                                    to="/portfolios/uiux-design/website-redesign"
                                    onClick={() =>
                                      this.getActiveClass("website_redesign", "portfolios")
                                    }
                                    title="Website Redesign"
                                  >
                                    <img src={landingpage} className="menu_items_icons" alt="Website Redesign" />
                                    Website Redesign
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "responsive_web_design"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign1}
                                >
                                  <Link
                                    to="/portfolios/uiux-design/responsive-web-design"
                                    onClick={() =>
                                      this.getActiveClass("responsive_web_design", "portfolios")
                                    }
                                    title="Responsive Web Design"
                                  >
                                    <img src={responsive} className="menu_items_icons" alt="Responsive Web Design" />
                                    Responsive Web Design
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "logo_designing"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign1}
                                >
                                  <Link
                                    to="/portfolios/uiux-design/logo-designing"
                                    onClick={() =>
                                      this.getActiveClass("logo_designing", "portfolios")
                                    }
                                    title="Logo Designing"
                                  >
                                    <img src={logoDesign} className="menu_items_icons" alt="Logo Designing" />
                                    Logo Designing
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "banner_designing"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign1}
                                >
                                  <Link
                                    to="/portfolios/uiux-design/banner-designing"
                                    onClick={() =>
                                      this.getActiveClass("banner_designing", "portfolios")
                                    }
                                    title="Banner Designing"
                                  >
                                    <img src={bannerDesign} className="menu_items_icons" alt="Banner Designing" />
                                    Banner Designing
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "brochure_designing"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign1}
                                >
                                  <Link
                                    to="/portfolios/uiux-design/brochure-designing"
                                    onClick={() =>
                                      this.getActiveClass("brochure_designing", "portfolios")
                                    }
                                    title="Brochure Designing"
                                  >
                                    <img src={brochureDesign} className="menu_items_icons" alt="Brochure Designing" />
                                    Brochure Designing
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "print_media_design"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderGraDesign1}
                                >
                                  <Link
                                    to="/portfolios/uiux-design/print-media-design"
                                    onClick={() =>
                                      this.getActiveClass("print_media_design", "portfolios")
                                    }
                                    title="Print Media Design"
                                  >
                                    <img src={printmedia} className="menu_items_icons" alt="Print Media Design" />
                                    Print Media Design
                                  </Link>
                                </MenuItem>
                              </Collapse>

                              {/* For Ecommerce Development */}
                              <MenuItem
                                className={
                                  this.state.isActive === "ecommerce-solutions" ||
                                    this.state.isActive === "magento_development" ||
                                    this.state.isActive === "shopify_development" ||
                                    this.state.isActive === "woocommerce_development" ||
                                    this.state.isActive === "open_cart_development" ||
                                    this.state.isActive === "bigcommerce_development"
                                    ? "active cat_head_title innerMenuItems"
                                    : "cat_head_title innerMenuItems"
                                }
                              //onClick={this.handleClickServicesEcoDev}
                              >
                                <Link
                                  to="/portfolios/ecommerce-solutions"
                                  className="cat_head_title"
                                  onClick={(event) => {
                                    this.getActiveClass("ecommerce-solutions", "portfolios")
                                    this.handleClickForHeaderEcoDev1()
                                  }}
                                  title="E-commerce Solutions"
                                >
                                  E-commerce Solutions
                                </Link>
                                {openMobileProtofolioEcoDev ? (
                                  <ExpandLess onClick={this.handleClickServicesEcoDev1} />
                                ) : (
                                    <ExpandMore onClick={this.handleClickServicesEcoDev1} />
                                  )}
                              </MenuItem>
                              <Collapse
                                in={openMobileProtofolioEcoDev}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "magento_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev1}
                                >
                                  <Link
                                    to="/portfolios/ecommerce-solutions/magento-development"
                                    onClick={() =>
                                      this.getActiveClass("magento_development", "portfolios")
                                    }
                                    title="Magento Development"
                                  >
                                    <img src={magento} className="menu_items_icons" alt="Magento Development" />
                                    Magento Development
                                  </Link>
                                </MenuItem> */}
                                <MenuItem
                                  className={
                                    this.state.isActive === "woocommerce_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev1}
                                >
                                  <Link
                                    to="/portfolios/ecommerce-solutions/woocommerce-development"
                                    onClick={() =>
                                      this.getActiveClass("woocommerce_development", "portfolios")
                                    }
                                    title="WooCommerce Development"
                                  >
                                    <img src={woocommerce} className="menu_items_icons" alt="WooCommerce Development" />
                                    WooCommerce Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "shopify_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev1}
                                >
                                  <Link
                                    to="/portfolios/ecommerce-solutions/shopify-development"
                                    onClick={() =>
                                      this.getActiveClass("shopify_development", "portfolios")
                                    }
                                    title="Shopify Development"
                                  >
                                    <img src={shopify} className="menu_items_icons" alt="Shopify Development" />
                                    Shopify Development
                                  </Link>
                                </MenuItem>

                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "open_cart_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev1}
                                >
                                  <Link
                                    to="/portfolios/ecommerce-solutions/open-cart-development"
                                    onClick={() =>
                                      this.getActiveClass("open_cart_development", "portfolios")
                                    }
                                    title="OpenCart Develeopment"
                                  >
                                    <img src={opencart} className="menu_items_icons" alt="OpenCart Redesign" />
                                    OpenCart Develeopment
                                  </Link>
                                </MenuItem> */}
                                {/* <MenuItem
                                  className={
                                    this.state.isActive === "bigcommerce_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForHeaderEcoDev1}
                                >
                                  <Link
                                    to="/portfolios/ecommerce-solutions/bigcommerce-development"
                                    onClick={() =>
                                      this.getActiveClass("bigcommerce_development", "portfolios")
                                    }
                                    title="BigCommerce Development"
                                  >
                                    <img src={header_bigcommerce_icon} className="menu_items_icons" alt="BigCommerce Development" />
                                    BigCommerce Development
                                  </Link>
                                </MenuItem> */}
                              </Collapse>


                              {/* For App Develeopment */}
                              <MenuItem
                                className={
                                  this.state.isActive === "application-development" ||
                                    this.state.isActive === "android_app_development" ||
                                    this.state.isActive === "ios_app_development" ||
                                    this.state.isActive === "react_native_development"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                              //onClick={this.handleClickServicesAppDev}
                              >
                                <Link
                                  to="/portfolios/application-development"
                                  onClick={(event) => {
                                    this.getActiveClass("application-development", "portfolios")
                                    this.handleClickForMobileHeaderApp1()
                                  }}
                                  title="Mobile App Development"
                                >
                                  Mobile App Development
                                </Link>
                                {openMobileProtofolioAppDev ? <ExpandLess onClick={this.handleClickServicesAppDev1} /> : <ExpandMore onClick={this.handleClickServicesAppDev1} />}
                              </MenuItem>
                              <Collapse
                                in={openMobileProtofolioAppDev}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding />
                                <MenuItem
                                  className={
                                    this.state.isActive === "android_app_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderApp1}
                                >
                                  <Link
                                    to="/portfolios/application-development/android-app-development"
                                    onClick={() =>
                                      this.getActiveClass("android_app_development", "portfolios")
                                    }
                                    title="Android App Development"
                                  >
                                    <img src={android} className="menu_items_icons" alt="Android App Development" />
                                    Android App Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "ios_app_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderApp1}
                                >
                                  <Link
                                    to="/portfolios/application-development/ios-app-development"
                                    onClick={() =>
                                      this.getActiveClass("ios_app_development", "portfolios")
                                    }
                                    title="iOS App Development"
                                  >
                                    <img src={apple} className="menu_items_icons" alt="iOS App Development" />
                                    iOS App Development
                                  </Link>
                                </MenuItem>
                                <MenuItem
                                  className={
                                    this.state.isActive === "react_native_development"
                                      ? "active innerMenuItemsService"
                                      : "innerMenuItemsService"
                                  }
                                  onClick={this.handleClickForMobileHeaderApp1}
                                >
                                  <Link
                                    to="/portfolios/application-development/react-native-development"
                                    onClick={() =>
                                      this.getActiveClass("react_native_development", "portfolios")
                                    }
                                    title="React Native Development"
                                  >
                                    <img src={reactNative} className="menu_items_icons" alt="React Native Development" />
                                    React Native Development
                                  </Link>
                                </MenuItem>
                              </Collapse>

                            </List>
                          </Collapse>
                        </MenuList>
                                  
                        <MenuList>
                          <MenuItem
                            className={
                              this.state.isActive === "careers"
                                ? "active innerMenuItems ml-0"
                                : "innerMenuItems ml-0"
                            }
                            onClick={this.handleClickForMobileHeader}
                          >
                            <Link
                              to="/careers"
                              onClick={() =>
                                this.getActiveClass("careers", "company")
                              }
                              title="Careers"
                            >
                              {/* <img src={Company_Career} className="menu_items_icons" alt="Careers" /> */}
                              Careers
                            </Link>
                          </MenuItem>
                        </MenuList>         
                        {/* For Company */}
                        <MenuList className="d-none">
                          <MenuItem
                            className={this.state.isheaderMenu === "company" ? "active" : ""}
                          //onClick={this.handleClick}
                          >
                            <Link
                              to="/about-us"
                              aria-owns={openCompany ? "menu-list-grow" : undefined}
                              aria-haspopup="true"
                              onClick={(event) => {
                                this.getActiveClass("", "company")
                                this.handleClickForMobileHeader()
                              }}
                              title="Company"
                            >
                              Company
                            </Link>
                            {open ? <ExpandLess onClick={this.handleClick} /> : <ExpandMore onClick={this.handleClick} />}
                          </MenuItem>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                              <MenuItem
                                className={
                                  this.state.isActive === "about-us"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForMobileHeader}
                              >
                                <Link
                                  to="/about-us"
                                  onClick={() =>
                                    this.getActiveClass("about-us", "company")
                                  }
                                  title="About Us"
                                >
                                  <img src={Company_About} className="menu_items_icons" alt="About Us" />
                                  About Us
                                </Link>
                              </MenuItem>
                              {/* <MenuItem
                                className={
                                  this.state.isActive === "ourteam"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForMobileHeader}
                              >
                                <Link
                                  to="/our-team"
                                  onClick={() =>
                                    this.getActiveClass("ourteam", "company")
                                  }
                                >
                                  Our Team
                                </Link>
                              </MenuItem> */}
                              <MenuItem
                                className={
                                  this.state.isActive === "ourapproach"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForMobileHeader}
                              >
                                <Link
                                  to="/our-approach"
                                  onClick={() =>
                                    this.getActiveClass("ourapproach", "company")
                                  }
                                  title="Our Approach"
                                >
                                  <img src={Company_Approach} className="menu_items_icons" alt="Our Approach" />
                                  Our Approach
                                </Link>
                              </MenuItem>
                              <MenuItem
                                className={
                                  this.state.isActive === "careers"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForMobileHeader}
                              >
                                <Link
                                  to="/careers"
                                  onClick={() =>
                                    this.getActiveClass("careers", "company")
                                  }
                                  title="Careers"
                                >
                                  <img src={Company_Career} className="menu_items_icons" alt="Careers" />
                                  Careers
                                </Link>
                              </MenuItem>
                              {/* <MenuItem
                                className={
                                  this.state.isActive === "get-free-quote"
                                    ? "active innerMenuItems"
                                    : "innerMenuItems"
                                }
                                onClick={this.handleClickForMobileHeader}
                              >
                                <Link
                                  to="/get-a-free-quote"
                                  onClick={() =>
                                    this.getActiveClass("get-free-quote", "company")
                                  }
                                >
                                  Get Free Quote
                                </Link>
                              </MenuItem> */}
                            </List>
                          </Collapse>
                        </MenuList>

                        <MenuItem className="contactUs_mobile">
                          <Link to="/contact-us" onClick={this.handleClickForMobileHeader} title="Contact Us">Contact Us</Link>
                        </MenuItem>
                      </MenuList>
                      <MenuList className="call_now_top">
                        <MenuItem className="header_call_now">
                          <div>
                            <a href={"tel:01724540666"} title="+91 172 - 4018666">
                              +91 172 - 4540666,
                            </a>{" "}
                            <a href={"tel:01724541666"} title="+91 172 - 4028666">
                              +91 172 - 4541666
                            </a>
                          </div>
                          <div>
                            <a href={"tel:01724542666"} title="+91 172 - 4018666">
                              +91 172 - 4542666,
                            </a>{" "}
                            <a href={"tel:01724543666"} title="+91 172 - 4028666">
                              +91 172 - 4543666
                            </a>
                          </div>
                          <Typography>Call Us Now</Typography>
                        </MenuItem>
                      </MenuList>
                      <MenuList className="menu_wrap">
                        <MenuItem className="social_links">
                          <a href={"https://www.facebook.com/visions.net.in"} title="Facebook" target="_blank" rel="noreferrer noopener">
                            <img src={facebook_logo} alt="Facebook" />
                          </a>
                          {/* <a href={"https://twitter.com/visionssoftware"} title="Twitter" target="_blank" rel="noreferrer noopener">
                            <img src={twitter_logo} alt="Twitter" />
                          </a> */}
                          <a href={"https://www.linkedin.com/company/visions-web-design-development-company-india/"} title="LinkedIn" target="_blank" rel="noreferrer noopener">
                            <img src={linkedin_logo} alt="LinkedIn" />
                          </a>
                        </MenuItem>
                      </MenuList>
                    </div>
                  </div>
                </Drawer>
              </Hidden>
              <Hidden mdDown>
                <MenuList className="menu_wrap">
                  {/* <MenuItem className="active home"><Link to="/">Home</Link></MenuItem> */}
                  <MenuItem
                    className={
                      this.state.isActive === "about-us"
                        ? "current_page_item"
                        : ""
                    }
                    onClick={this.handleCompanyToggle2}
                  >
                    <Link
                      to="/about-us"
                      onClick={() =>
                        this.getActiveClass("about-us", "company")
                      }
                      title="About Us"
                    >
                      {/* <img src={Company_About} className="menu_items_icons" alt="About Us" /> */}
                      About Us
                      </Link>
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={this.handleServicesToggle}
                    onMouseLeave={this.handleServicesToggle2}
                    className={
                      this.state.isheaderMenu === "services"
                        ? "active mega_menu"
                        : "mega_menu"
                    }
                  >
                    <Link
                      to="/services"
                      aria-owns={openServices ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={() =>
                        this.getActiveClass("services", "services")
                      }
                      title="Services"
                    >
                      Services{" "}
                      <Icon className="arrow_down">keyboard_arrow_down</Icon>
                    </Link>
                    <Popper
                      open={openServices}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom"
                          }}
                        >
                          <Paper>
                            {/* <ClickAwayListener
                              onClickAway={this.handleServicesToggle}
                            > */}
                            <MenuList className="sub_menu">
                              <MenuItem className="sub_menu_categories_row">
                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "web-development-services"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "web-development-services",
                                          "services"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="Web Development"
                                    >
                                      Web Development
                                        </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "php-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services/php-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "php-development",
                                          "services"
                                        )
                                      }
                                      title="PHP Development"
                                    >
                                      <img src={php} className="menu_items_icons" alt="PHP Development" />
                                      <span className="text_wrap">PHP Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mean-stack-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services/mean-stack-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mean-stack-development",
                                          "services"
                                        )
                                      }
                                      title="Mean Stack Development"
                                    >
                                      <img src={header_mean_icon} className="menu_items_icons" alt="MEAN Stack Development" />
                                      <span className="text_wrap">Mean Stack Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mern-stack-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services/mern-stack-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mern-stack-development",
                                          "services"
                                        )
                                      }
                                      title="Mern Stack Development"
                                    >
                                      <img src={header_mern_icon} className="menu_items_icons" alt="MERN Stack Development" />
                                      <span className="text_wrap">Mern Stack Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mevn-stack-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services/mevn-stack-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mevn-stack-development",
                                          "services"
                                        )
                                      }
                                      title="Mevn Stack Development"
                                    >
                                      <img src={header_mevn_icon} className="menu_items_icons" alt="Mevn Stack Development" />
                                      <span className="text_wrap">Mevn Stack Development</span>
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "asp-net-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services/asp-net-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "asp-net-development",
                                          "services"
                                        )
                                      }
                                      title="Asp.Net Development"
                                    >
                                      <img src={net} className="menu_items_icons" alt="ASP.NET Development" />
                                      <span className="text_wrap">Asp.Net Development</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "python-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/web-development-services/python-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "python-development",
                                          "services"
                                        )
                                      }
                                      title="Python Development"
                                    >
                                      <img src={header_python_icon} className="menu_items_icons" alt="Python Development" />
                                      <span className="text_wrap">Python Development</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>

                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "graphic-design-services"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "graphic-design-services",
                                          "services"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="UI/UX"
                                    >
                                      UI/UX
                                      </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "website-redesign"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/website-redesign"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "website-redesign",
                                          "services"
                                        )
                                      }
                                      title="Website Redesign"
                                    >
                                      <img src={landingpage} className="menu_items_icons" alt="Website Redesign" />
                                      <span className="text_wrap">Website Redesign</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "responsive-web-design"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/responsive-web-design"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "responsive-web-design",
                                          "services"
                                        )
                                      }
                                      title="Responsive Web Design"
                                    >
                                      <img src={responsive} className="menu_items_icons" alt="Responsive Web Design" />
                                      <span className="text_wrap">Responsive Web Design</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "logo-designing"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/logo-designing"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "logo-designing",
                                          "services"
                                        )
                                      }
                                      title="Logo Designing"
                                    >
                                      <img src={logoDesign} className="menu_items_icons" alt="Logo Designing" />
                                      <span className="text_wrap">Logo Designing</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "banner-designing"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/banner-designing"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "banner-designing",
                                          "services"
                                        )
                                      }
                                      title="Banner Designing"
                                    >
                                      <img src={bannerDesign} className="menu_items_icons" alt="Banner Designing" />
                                      <span className="text_wrap">Banner Designing</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "brochure-designing"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/brochure-designing"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "brochure-designing",
                                          "services"
                                        )
                                      }
                                      title="Brochure Designing"
                                    >
                                      <img src={brochureDesign} className="menu_items_icons" alt="Brochure Designing" />
                                      <span className="text_wrap">Brochure Designing</span>
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "print-media-design"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/print-media-design"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "print-media-design",
                                          "services"
                                        )
                                      }
                                      title="Print Media Design"
                                    >
                                      <img src={printmedia} className="menu_items_icons" alt="Print Media Design" />
                                      <span className="text_wrap">Print Media Design</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mobile-app-design"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/uiux-design-services/mobile-app-design"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mobile-app-design",
                                          "services"
                                        )
                                      }
                                      title="Mobile App Design"
                                    >
                                      <img src={mobileAppIcon} className="menu_items_icons" alt="Mobile App Design" />
                                      <span className="text_wrap">Mobile App Design</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>

                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "ecommerce-development-services"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/ecommerce-development-services"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "ecommerce-development-services",
                                          "services"
                                        )
                                      }
                                      title="E-commerce Solutions"
                                      className="cat_head_title"
                                    >
                                      E-commerce Solutions
                                        </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "magento-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/ecommerce-development-services/magento-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "magento-development",
                                          "services"
                                        )
                                      }
                                      title="Magento Development"
                                    >
                                      <img src={magento} className="menu_items_icons" alt="Magento Development" />
                                      <span className="text_wrap">Magento Development</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "woocommerce-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/ecommerce-development-services/woocommerce-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "woocommerce-development",
                                          "services"
                                        )
                                      }
                                      title="WooCommerce Development"
                                    >
                                      <img src={woocommerce} className="menu_items_icons" alt="WooCommerce Development" />
                                      <span className="text_wrap">WooCommerce Development</span>
                                    </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "shopify-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/ecommerce-development-services/shopify-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "shopify-development",
                                          "services"
                                        )
                                      }
                                      title="Shopify Development"
                                    >
                                      <img src={shopify} className="menu_items_icons" alt="Shopify Development" />
                                      <span className="text_wrap">Shopify Development</span>
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "openCart-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/ecommerce-development-services/openCart-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "openCart-development",
                                          "services"
                                        )
                                      }
                                      title="OpenCart Development"
                                    >
                                      <img src={opencart} className="menu_items_icons" alt="OpenCart Development" />
                                      <span className="text_wrap">OpenCart Development</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "bigcommerce-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/ecommerce-development-services/bigcommerce-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "bigcommerce-development",
                                          "services"
                                        )
                                      }
                                      title="BigCommerce Development"
                                    >
                                      <img src={header_bigcommerce_icon} className="menu_items_icons" alt="BigCommerce Development" />
                                      <span className="text_wrap">BigCommerce Development</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>


                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "application-development-services"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/app-development-services"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "application-development-services",
                                          "services"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="Mobile App Development"
                                    >
                                      Mobile App Development
                                      </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "android-app-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/app-development-services/android-app-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "android-app-development",
                                          "services"
                                        )
                                      }
                                      title="Android App Development"
                                    >
                                      <img src={android} className="menu_items_icons" alt="Android App Development" />
                                      <span className="text_wrap">Android App Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "ios-app-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/app-development-services/ios-app-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "ios-app-development",
                                          "services"
                                        )
                                      }
                                      title="iOS App Development"
                                    >
                                      <img src={apple} className="menu_items_icons" alt="iOS App Development" />
                                      <span className="text_wrap">iOS App Development</span>
                                    </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "react-native-development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/app-development-services/react-native-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "react-native-development",
                                          "services"
                                        )
                                      }
                                      title="React Native Development"
                                    >
                                      <img src={reactNative} className="menu_items_icons" alt="React Native Development" />
                                      <span className="text_wrap">React Native Development</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>

                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive === "server-network-admin-support"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/server-network-admin-support-services"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "server-network-admin-support",
                                          "services"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="Server/Network Admin Support"
                                    >
                                      Server/Network Admin Support
                                      </Link>
                                  </MenuItem>                                  
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "server-network-administration"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/server-network-admin-support-services/server-network-administration"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "server-network-administration",
                                          "services"
                                        )
                                      }
                                      title="Server Network Administration"
                                    >
                                      <img src={server_admin_support} className="menu_items_icons" alt="Server Network Administration" />
                                      <span className="text_wrap">Server Network Administration</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "aws-cloud-hosting"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/server-network-admin-support-services/aws-cloud-hosting"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "aws-cloud-hosting",
                                          "services"
                                        )
                                      }
                                      title="AWS Cloud Hosting"
                                    >
                                      <img src={header_awsCloud_icon} className="menu_items_icons" alt="AWS Cloud Hosting" />
                                      <span className="text_wrap">AWS Cloud Hosting</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "linux"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/server-network-admin-support-services/linux"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "linux",
                                          "services"
                                        )
                                      }
                                      title="Linux"
                                    >
                                      <img src={header_linux_icon} className="menu_items_icons" alt="Linux" />
                                      <span className="text_wrap">linux</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "email-migration"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/server-network-admin-support-services/email-migration"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "email-migration",
                                          "services"
                                        )
                                      }
                                      title="Email Migration"
                                    >
                                      <img src={header_emailmigration_icon} className="menu_items_icons" alt="Email Migration" />
                                      <span className="text_wrap">Email Migration</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>

                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive === "digital-marketing-services"
                                        ? "active current_page_item cat_head_title cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/digital-marketing-services"
                                      onClick={() =>
                                        this.getActiveClass("digital-marketing-services", "services")
                                      }
                                      className="cat_head_title"
                                      title="Digital Marketing"
                                    >
                                      Digital Marketing
                                      </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "seo-services-india"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/digital-marketing-services/seo-services-india"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "seo-services-india",
                                          "services"
                                        )
                                      }
                                      title="Search Engine Optimization"
                                    >
                                      <img src={seo} className="menu_items_icons" alt="Search Engine Optimization" />
                                      <span className="text_wrap">Search Engine Optimization</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "local-seo"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/digital-marketing-services/local-seo"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "local-seo",
                                          "services"
                                        )
                                      }
                                      title="Local SEO"
                                    >
                                      <img src={seo2} className="menu_items_icons" alt="Local SEO" />
                                      <span className="text_wrap">Local SEO</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "social-media-optimization"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/digital-marketing-services/social-media-optimization"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "social-media-optimization",
                                          "services"
                                        )
                                      }
                                      title="Social Media Optimization"
                                    >
                                      <img src={socialMediaSeo} className="menu_items_icons" alt="Social Media Optimization" />
                                      <span className="text_wrap">Social Media Optimization</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "online-reputation-management"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handleServicesToggle2}
                                  >
                                    <Link
                                      to="/services/digital-marketing-services/online-reputation-management"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "online-reputation-management",
                                          "services"
                                        )
                                      }
                                      title="Online Reputation Management"
                                    >
                                      <img src={online_reputation_icon} className="menu_items_icons" alt="Online Reputation Management" />
                                      <span className="text_wrap">Online Reputation Management</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>


                              </MenuItem>
                            </MenuList>
                            {/* </ClickAwayListener> */}
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={this.handleDevelopersToggle}
                    onMouseLeave={this.handleDevelopersToggle2}
                    className={
                      this.state.isheaderMenu === "hire-developers"
                        ? "active mega_menu hire_developers d-none"
                        : "mega_menu hire_developers d-none"
                    }
                  >
                    <Link
                      to="/hire-developers/hire-android-app-developers"
                      aria-owns={openDevelopers ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={() =>
                        this.getActiveClass("hire-developers", "hire-developers")
                      }
                      title="Hire Developers"
                    >
                      Hire Developers{" "}
                      <Icon className="arrow_down">keyboard_arrow_down</Icon>
                    </Link>
                    <Popper
                      open={openDevelopers}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom"
                          }}
                        >
                          <Paper>
                            {/* <ClickAwayListener
                              onClickAway={this.handleDevelopersToggle}
                            > */}
                            <MenuList className="sub_menu hireDevelopersMenu">

                              {/* Hire Android App Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-android-app-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-android-app-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-android-app-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Android App Developers"
                                >
                                  <img src={android} className="menu_items_icons" alt="Android App Developers" />
                                  Android App Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Angular Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-angularjs-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-angular-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-angularjs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Angular Developers"
                                >
                                  <img src={angular_js} className="menu_items_icons" alt="Angular Developers" />
                                  Angular Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Aspnet Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-asp-net-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-asp-net-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-asp-net-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="ASP.Net Developers"
                                >
                                  <img src={net} className="menu_items_icons" alt="ASP.Net Developers" />
                                  ASP.Net Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire codeigniter Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-codeigniter-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-codeigniter-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-codeigniter-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Codeigniter Developers"
                                >
                                  <img src={codeigniter} className="menu_items_icons" alt="Codeigniter Developers" />
                                  Codeigniter Developers
                                  </Link>
                              </MenuItem> 
                              
                              {/* Hire Drupal Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-drupal-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-drupal-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-drupal-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Drupal Developers"
                                >
                                  <img src={drupal} className="menu_items_icons" alt="Drupal Developers" />
                                  Drupal Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire IOS App Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-ios-app-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-ios-app-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-ios-app-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="iOS App Developers"
                                >
                                  <img src={apple} className="menu_items_icons" alt="iOS App Developers" />
                                  iOS App Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Laravel Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-laravel-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-laravel-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-laravel-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Laravel Developers"
                                >
                                  <img src={lareval} className="menu_items_icons" alt="Laravel Developers" />
                                  Laravel Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Magento Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-magento-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-magento-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-magento-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Magento Developers"
                                >
                                  <img src={magento} className="menu_items_icons" alt="Magento Developers" />
                                  Magento Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Nodejs Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-nodejs-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-nodejs-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-nodejs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="NodeJS Developers"
                                >
                                  <img src={node_js} className="menu_items_icons" alt="NodeJS Developers" />
                                  NodeJS Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire PHP Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-php-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-php-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-php-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="PHP Developers"
                                >
                                  <img src={php} className="menu_items_icons" alt="PHP Developers" />
                                  PHP Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Python Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-python-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-python-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-python-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Python Developers"
                                >
                                  <img src={header_python_icon} className="menu_items_icons" alt="Python Developers" />
                                  Python Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire React Native App Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-react-native-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-react-native-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-react-native-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="React Native Developers"
                                >
                                  <img src={reactNative} className="menu_items_icons" alt="React Native Developers" />
                                  React Native Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Reactjs Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-reactjs-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-reactjs-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-reactjs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="ReactJS Developers"
                                >
                                  <img src={reactNative} className="menu_items_icons" alt="ReactJS Developers" />
                                  ReactJS Developers
                                  </Link>
                              </MenuItem>

                              {/* Hire Vuejs Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-vuejs-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-vuejs-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-vuejs-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="VueJS Developers"
                                >
                                  <img src={vuejs} className="menu_items_icons" alt="VueJS Developers" />
                                  VueJS Developers
                                  </Link>
                              </MenuItem> 

                              {/* Hire Wordpress Developers */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-wordpress-developers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-wordpress-developers"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-wordpress-developers",
                                      "hire-developers"
                                    )
                                  }
                                  title="Wordpress Developers"
                                >
                                  <img src={wordpress_icon} className="menu_items_icons" alt="Wordpress Developers" />
                                  Wordpress Developers
                                  </Link>
                              </MenuItem>  

                              {/* Hire Web Designer */}
                              <MenuItem
                                className={
                                  this.state.isActive ===
                                    "hire-web-designer"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleDevelopersToggle2}
                              >
                                <Link
                                  to="/hire-developers/hire-web-designer"
                                  onClick={() =>
                                    this.getActiveClass(
                                      "hire-web-designer",
                                      "hire-developers"
                                    )
                                  }
                                  title="Web Designer"
                                >
                                  <img src={responsive} className="menu_items_icons" alt="Web Designer" />
                                  Web Designer
                                  </Link>
                              </MenuItem>

                            </MenuList>
                            {/* </ClickAwayListener> */}
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={this.handlePortfolioToggle}
                    onMouseLeave={this.handlePortfolioToggle2}
                    className={
                      this.state.isheaderMenu === "portfolios" ? "active mega_menu_short portfolios" : "mega_menu_short portfolios"
                    }
                  >
                    <Link
                      to="/portfolios"
                      aria-owns={openPortfolio ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={() =>
                        this.getActiveClass("portfolios", "portfolios")
                      }
                      title="Portfolios"
                    >
                      Portfolios{" "}
                      <Icon className="arrow_down">keyboard_arrow_down</Icon>
                    </Link>
                    <Popper
                      open={openPortfolio}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom"
                          }}
                        >
                          <Paper>
                            {/* <ClickAwayListener
                              onClickAway={this.handlePortfolioToggle}
                            > */}
                            {/* <MenuList className="sub_menu">
                                <MenuItem
                                  className={
                                    this.state.isActive === "wordpress"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handlePortfolioToggle2}
                                >
                                  <Link
                                    to="/portfolio/wordpress"
                                    onClick={() =>
                                      this.getActiveClass("wordpress", "portfolios")
                                    }
                                  >
                                    Wordpress Development
                                  </Link>
                                </MenuItem>
                              </MenuList>
                              <MenuList className="sub_menu">
                                <MenuItem
                                  className={
                                    this.state.isActive === "magento"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handlePortfolioToggle2}
                                >
                                  <Link
                                    to="/portfolio/magento"
                                    onClick={() =>
                                      this.getActiveClass("magento", "portfolios")
                                    }
                                  >
                                    Magento Development
                                  </Link>
                                </MenuItem>
                              </MenuList>
                              <MenuList className="sub_menu">
                                <MenuItem
                                  className={
                                    this.state.isActive === "drupal"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handlePortfolioToggle2}
                                >
                                  <Link
                                    to="/portfolio/drupal"
                                    onClick={() =>
                                      this.getActiveClass("drupal", "portfolios")
                                    }
                                  >
                                    Drupal Development
                                  </Link>
                                </MenuItem>
                              </MenuList>
                              <MenuList className="sub_menu">
                                <MenuItem
                                  className={
                                    this.state.isActive === "reactjs"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handlePortfolioToggle2}
                                >
                                  <Link
                                    to="/portfolio/reactjs"
                                    onClick={() =>
                                      this.getActiveClass("reactjs", "portfolios")
                                    }
                                  >
                                    ReactJs Development
                                  </Link>
                                </MenuItem>
                              </MenuList>
                              <MenuList className="sub_menu">
                                <MenuItem
                                  className={
                                    this.state.isActive === "react-native"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handlePortfolioToggle2}
                                >
                                  <Link
                                    to="/portfolio/react-native"
                                    onClick={() =>
                                      this.getActiveClass("react-native", "portfolios")
                                    }
                                  >
                                    React Native Development
                                  </Link>
                                </MenuItem>
                              </MenuList> */}

                              {/* by sahil */}

                            {/* <MenuList className="sub_menu">
                              <MenuItem
                                className={
                                  this.state.isActive === "web-development"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handlePortfolioToggle2}
                              >
                                <Link
                                  to="/portfolios/web-development"
                                  onClick={() =>
                                    this.getActiveClass("web-development", "portfolios")
                                  }
                                  title="Web Development"
                                >
                                  <img src={Web_DevelopmentPortfolio} className="menu_items_icons" alt="Web Development" />
                                  Web Development
                                  </Link>
                              </MenuItem>
                            </MenuList>
                            <MenuList className="sub_menu">
                              <MenuItem
                                className={
                                  this.state.isActive === "uiux-design"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handlePortfolioToggle2}
                              >
                                <Link
                                  to="/portfolios/uiux-design"
                                  onClick={() =>
                                    this.getActiveClass("uiux-design", "portfolios")
                                  }
                                  title="UI/UX Design"
                                >
                                  <img src={UI_UX_DesignPortfolio} className="menu_items_icons" alt="UI/UX Design" />
                                  UI/UX Design
                                  </Link>
                              </MenuItem>
                            </MenuList>
                            <MenuList className="sub_menu">
                              <MenuItem
                                className={
                                  this.state.isActive === "ecommerce-solutions"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handlePortfolioToggle2}
                              >
                                <Link
                                  to="/portfolios/ecommerce-solutions"
                                  onClick={() =>
                                    this.getActiveClass("ecommerce-solutions", "portfolios")
                                  }
                                  title="E-Commerce Solutions"
                                >
                                  <img src={E_Commerce_SolutionsPortfolio} className="menu_items_icons" alt="E-Commerce Solutions" />
                                  E-Commerce Solutions
                                  </Link>
                              </MenuItem>
                            </MenuList>
                            <MenuList className="sub_menu">
                              <MenuItem
                                className={
                                  this.state.isActive === "application-development"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handlePortfolioToggle2}
                              >
                                <Link
                                  to="/portfolios/application-development"
                                  onClick={() =>
                                    this.getActiveClass("application-development", "portfolios")
                                  }
                                  title="App Development"
                                >
                                  <img src={Application_DevelopmentPortfolio} className="menu_items_icons" alt="App Development" />
                                  App Development
                                  </Link>
                              </MenuItem>
                            </MenuList> */}

                            <MenuList className="sub_menu">
                              <MenuItem className="sub_menu_categories_row_port">
                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "web-development"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "web-development",
                                          "portfolios"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="Web Development"
                                    >
                                      Web Development
                                        </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "php_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development/php-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "php_development",
                                          "portfolios"
                                        )
                                      }
                                      title="PHP Development"
                                    >
                                      <img src={php} className="menu_items_icons" alt="PHP Development" />
                                      <span className="text_wrap">PHP Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mean_stack_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development/mean-stack-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mean_stack_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Mean Stack Development"
                                    >
                                      <img src={header_mean_icon} className="menu_items_icons" alt="MEAN Stack Development" />
                                      <span className="text_wrap">Mean Stack Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mern_stack_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development/mern-stack-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mern_stack_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Mern Stack Development"
                                    >
                                      <img src={header_mern_icon} className="menu_items_icons" alt="MERN Stack Development" />
                                      <span className="text_wrap">Mern Stack Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "mevn_stack_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development/mevn-stack-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "mevn_stack_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Mevn Stack Development"
                                    >
                                      <img src={header_mevn_icon} className="menu_items_icons" alt="Mevn Stack Development" />
                                      <span className="text_wrap">Mevn Stack Development</span>
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "asp_net_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development/asp-net-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "asp_net_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Asp.Net Development"
                                    >
                                      <img src={net} className="menu_items_icons" alt="ASP.NET Development" />
                                      <span className="text_wrap">Asp.Net Development</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "python_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/web-development/python-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "python_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Python Development"
                                    >
                                      <img src={header_python_icon} className="menu_items_icons" alt="Python Development" />
                                      <span className="text_wrap">Python Development</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>
                                
                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "uiux-design"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "uiux-design",
                                          "portfolios"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="UI/UX"
                                    >
                                      UI/UX
                                      </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "website_redesign"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design/website-redesign"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "website_redesign",
                                          "portfolios"
                                        )
                                      }
                                      title="Website Redesign"
                                    >
                                      <img src={landingpage} className="menu_items_icons" alt="Website Redesign" />
                                      <span className="text_wrap">Website Redesign</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "responsive_web_design"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design/responsive-web-design"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "responsive_web_design",
                                          "portfolios"
                                        )
                                      }
                                      title="Responsive Web Design"
                                    >
                                      <img src={responsive} className="menu_items_icons" alt="Responsive Web Design" />
                                      <span className="text_wrap">Responsive Web Design</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "logo_designing"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design/logo-designing"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "logo_designing",
                                          "portfolios"
                                        )
                                      }
                                      title="Logo Designing"
                                    >
                                      <img src={logoDesign} className="menu_items_icons" alt="Logo Designing" />
                                      <span className="text_wrap">Logo Designing</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "banner_designing"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design/banner-designing"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "banner_designing",
                                          "portfolios"
                                        )
                                      }
                                      title="Banner Designing"
                                    >
                                      <img src={bannerDesign} className="menu_items_icons" alt="Banner Designing" />
                                      <span className="text_wrap">Banner Designing</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "brochure_designing"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design/brochure-designing"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "brochure_designing",
                                          "portfolios"
                                        )
                                      }
                                      title="Brochure Designing"
                                    >
                                      <img src={brochureDesign} className="menu_items_icons" alt="Brochure Designing" />
                                      <span className="text_wrap">Brochure Designing</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "print_media_design"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/uiux-design/print-media-design"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "print_media_design",
                                          "portfolios"
                                        )
                                      }
                                      title="Print Media Design"
                                    >
                                      <img src={mobileAppIcon} className="menu_items_icons" alt="Print Media Design" />
                                      <span className="text_wrap">Print Media Design</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>

                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "ecommerce-solutions"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/ecommerce-solutions"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "ecommerce-solutions",
                                          "portfolios"
                                        )
                                      }
                                      title="E-commerce Solutions"
                                      className="cat_head_title"
                                    >
                                      E-commerce Solutions
                                        </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "magento_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/ecommerce-solutions/magento-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "magento_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Magento Development"
                                    >
                                      <img src={magento} className="menu_items_icons" alt="Magento Development" />
                                      <span className="text_wrap">Magento Development</span>
                                    </Link>
                                  </MenuItem> */}
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "woocommerce_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/ecommerce-solutions/woocommerce-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "woocommerce_development",
                                          "portfolios"
                                        )
                                      }
                                      title="WooCommerce Development"
                                    >
                                      <img src={woocommerce} className="menu_items_icons" alt="WooCommerce Development" />
                                      <span className="text_wrap">WooCommerce Development</span>
                                    </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "shopify_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/ecommerce-solutions/shopify-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "shopify_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Shopify Development"
                                    >
                                      <img src={shopify} className="menu_items_icons" alt="Shopify Development" />
                                      <span className="text_wrap">Shopify Development</span>
                                    </Link>
                                  </MenuItem>
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "open_cart_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/ecommerce-solutions/open-cart-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "open_cart_development",
                                          "portfolios"
                                        )
                                      }
                                      title="OpenCart Development"
                                    >
                                      <img src={opencart} className="menu_items_icons" alt="OpenCart Development" />
                                      <span className="text_wrap">OpenCart Development</span>
                                    </Link>
                                  </MenuItem> */}
                                  {/* <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "bigcommerce_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/ecommerce-solutions/bigcommerce-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "bigcommerce_development",
                                          "portfolios"
                                        )
                                      }
                                      title="BigCommerce Development"
                                    >
                                      <img src={header_bigcommerce_icon} className="menu_items_icons" alt="BigCommerce Development" />
                                      <span className="text_wrap">BigCommerce Development</span>
                                    </Link>
                                  </MenuItem> */}
                                </MenuList>

                                <MenuList className="sub_menu_categories">
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "application-development"
                                        ? "current_page_item cat_head_title"
                                        : "cat_head_title"
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/application-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "application-development",
                                          "portfolios"
                                        )
                                      }
                                      className="cat_head_title"
                                      title="Mobile App Development"
                                    >
                                      Mobile App Development
                                      </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "android_app_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/application-development/android-app-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "android_app_development",
                                          "portfolios"
                                        )
                                      }
                                      title="Android App Development"
                                    >
                                      <img src={android} className="menu_items_icons" alt="Android App Development" />
                                      <span className="text_wrap">Android App Development</span>
                                    </Link>
                                  </MenuItem>
                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "ios_app_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/application-development/ios-app-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "ios_app_development",
                                          "portfolios"
                                        )
                                      }
                                      title="iOS App Development"
                                    >
                                      <img src={apple} className="menu_items_icons" alt="iOS App Development" />
                                      <span className="text_wrap">iOS App Development</span>
                                    </Link>
                                  </MenuItem>

                                  <MenuItem
                                    className={
                                      this.state.isActive ===
                                        "react_native_development"
                                        ? "current_page_item"
                                        : ""
                                    }
                                    onClick={this.handlePortfolioToggle2}
                                  >
                                    <Link
                                      to="/portfolios/application-development/react-native-development"
                                      onClick={() =>
                                        this.getActiveClass(
                                          "react_native_development",
                                          "portfolios"
                                        )
                                      }
                                      title="React Native Development"
                                    >
                                      <img src={reactNative} className="menu_items_icons" alt="React Native Development" />
                                      <span className="text_wrap">React Native Development</span>
                                    </Link>
                                  </MenuItem>
                                </MenuList>

                                </MenuItem>
                            </MenuList>
                            {/* </ClickAwayListener> */}
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </MenuItem>
                  <MenuItem
                    className={
                      this.state.isActive === "careers"
                        ? "current_page_item"
                        : ""
                    }
                    onClick={this.handleCompanyToggle2}
                  >
                    <Link
                      to="/careers"
                      onClick={() =>
                        this.getActiveClass("careers", "company")
                      }
                      title="Careers"
                    >
                      {/* <img src={Company_Career} className="menu_items_icons" alt="Careers" /> */}
                      Careers
                      </Link>
                  </MenuItem>
                  <MenuItem
                    onMouseEnter={this.handleCompanyToggle}
                    onMouseLeave={this.handleCompanyToggle2}
                    className={
                      this.state.isheaderMenu === "company" ? "active d-none" : "company d-none"
                    }
                  >
                    <Link
                      to="/about-us"
                      aria-owns={openCompany ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={() =>
                        this.getActiveClass("company", "company")
                      }
                      title="Company"
                    >
                      Company{" "}
                      <Icon className="arrow_down">keyboard_arrow_down</Icon>
                    </Link>
                    <Popper
                      open={openCompany}
                      anchorEl={this.anchorEl}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          id="menu-list-grow"
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom"
                          }}
                        >
                          <Paper>
                            {/* <ClickAwayListener
                              onClickAway={this.handleCompanyToggle}
                            > */}
                            <MenuList className="sub_menu">
                              <MenuItem
                                className={
                                  this.state.isActive === "about-us"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleCompanyToggle2}
                              >
                                <Link
                                  to="/about-us"
                                  onClick={() =>
                                    this.getActiveClass("about-us", "company")
                                  }
                                  title="About Us"
                                >
                                  <img src={Company_About} className="menu_items_icons" alt="About Us" />
                                  About Us
                                  </Link>
                              </MenuItem>
                              {/* <MenuItem
                                  className={
                                    this.state.isActive === "ourteam"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handleCompanyToggle2}
                                >
                                  <Link
                                    to="/our-team"
                                    onClick={() =>
                                      this.getActiveClass("ourteam", "company")
                                    }
                                  >
                                    Our Team
                                  </Link>
                                </MenuItem> */}
                              <MenuItem
                                className={
                                  this.state.isActive === "ourapproach"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleCompanyToggle2}
                              >
                                <Link
                                  to="/our-approach"
                                  onClick={() =>
                                    this.getActiveClass("ourapproach", "company")
                                  }
                                  title="Our Approach"
                                >
                                  <img src={Company_Approach} className="menu_items_icons" alt="Our Approach" />
                                  Our Approach
                                  </Link>
                              </MenuItem>

                              <MenuItem
                                className={
                                  this.state.isActive === "careers"
                                    ? "current_page_item"
                                    : ""
                                }
                                onClick={this.handleCompanyToggle2}
                              >
                                <Link
                                  to="/careers"
                                  onClick={() =>
                                    this.getActiveClass("careers", "company")
                                  }
                                  title="Careers"
                                >
                                  <img src={Company_Career} className="menu_items_icons" alt="Careers" />
                                  Careers
                                  </Link>
                              </MenuItem>

                              {/* <MenuItem
                                  className={
                                    this.state.isActive === "get-free-quote"
                                      ? "current_page_item"
                                      : ""
                                  }
                                  onClick={this.handleCompanyToggle2}
                                >
                                  <Link
                                    to="/get-a-free-quote"
                                    onClick={() =>
                                      this.getActiveClass("get-free-quote", "company")
                                    }
                                  >
                                    Get Free Quote
                                  </Link>
                                </MenuItem> */}
                            </MenuList>
                            {/* </ClickAwayListener> */}
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </MenuItem>
                  <MenuItem
                    className={
                      this.state.isActive === "contact"
                        ? "current_page_item"
                        : ""
                    }
                    onClick={this.handleCompanyToggle2}
                  >
                    <Link to="/contact-us" className="hover-btn-animation-out-old"
                      onClick={() =>
                        this.getActiveClass("contact", "contact")

                      } title="Contact Us">Contact Us</Link>
                  </MenuItem>
                </MenuList>
                
              </Hidden>
              <MenuList className="call_now_top">
                <MenuItem>
                  <div className="first_number">
                    <a href={"tel:01724540666"} title="+91 172 - 4540666">
                      <Icon title="Phone">phone</Icon> +91 172 - 4540666
                    </a>
                  </div>
                </MenuItem>
                <MenuItem>
                  <div className="second_number">
                    <a href={"tel:01724541666"} title="+91 172 - 4541666">
                        +91 172 - 4541666
                      </a>
                  </div>
                </MenuItem>
              </MenuList>
              <Hidden lgUp>
                <IconButton
                  color="inherit"
                  aria-label="Menu"
                  onClick={this.toggleDrawer("right", true)}
                  title="Menu"
                >
                  <MenuIcon className="menu_bar" />
                </IconButton>
              </Hidden>
            </aside>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default Header;
