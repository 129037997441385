import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FormControl } from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import SimpleReactValidator from "simple-react-validator";
import { NotificationContainer, NotificationManager } from "react-notifications";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";

// Get Images Start
import call from "../assets/images/call.svg";
import location from "../assets/images/location.svg";
import mail from "../assets/images/mail.svg";
import user from "../assets/images/user.svg";
import contact_banner from "../assets/images/contact/contact_banner.jpg";
// Get Images End

// Custom Stylesheets Start
import "react-notifications/lib/notifications.css";
import "../assets/css/contact.css";
// Custom Stylesheets End

// Get Common Components Start
import InnerPagesBanner from "../components/common/InnerPagesBanner";
import helper from "../helper";
// Get Common Components End

require("es6-promise").polyfill();
require("isomorphic-fetch");

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "Contact Us",
      bannerImg: contact_banner,
      name: "",
      email: "",
      phone: "",
      subject: "",
      enquiry: "",
      gCaptcha: "",
      loader: false
    };
    this.validator = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
  }

  componentWillMount() {
    let isAuthenticated = !localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      this.props.history.push("/login");
      window.location.reload();
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ loader: true });
      fetch(helper.API_URL + "/contacts/addContact", {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          subject: this.state.subject,
          enquiry: this.state.enquiry,
          gCaptcha: this.state.gCaptcha
        })
      })
        .then(response => {
          if (response.status === 200) {
            NotificationManager.success("Your Information Submitted Successfully. We will get back to you as soon as possible.");
            this.setState({ loader: false });
          } else {
            NotificationManager.error(
              "Something went wrong. Please try again."
            );
            this.setState({ loader: false });
          }
          return response.json();
        })
        .catch(error => {
          NotificationManager.error("Something went wrong. Please try again.");
          this.setState({ loader: false });
        });
      this.setState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        enquiry: "",
        gCaptcha: ""
      });
      this.captcha.reset();
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
    }
  };

  handleCaptcha = (value) => {
    this.setState({ gCaptcha: value })
  }

  render() {
    document.title = "Contact Us";
    const { pageTitle, bannerImg, name, email, phone, subject, enquiry, gCaptcha } = this.state;
    return (
      <Grid component="div" className="contact_page_wrapper">
        <Grid container>

          <NotificationContainer />
          <Grid item xs={12} component="div" className="get-free-quote-wrapper contact">

            {/* Start - InnerPagesBanner Import From Common Components */}
            <InnerPagesBanner pageTitle={pageTitle} bannerImg={bannerImg} />
            {/* End - InnerPagesBanner Import From Common Components */}

            {/* Start - Section Design "Contact Form" */}
            <Grid item xs={12} component="div" className="contact-pg">
              <Grid item container className="container_custom">
                <div className="inner-wrapper">
                  <Grid
                    component="div"
                    className="inner-get-free-quote sec_padding"
                  >
                    <Typography variant="h2" className="head_title">
                      Contact Us
                    </Typography>
                    <Typography className="head_paragraph">
                      Please submit the form below and we will get back to you as soon as possible.
                    </Typography>
                    <form
                      noValidate
                      autoComplete="off"
                      onSubmit={this.handleSubmit}
                    >
                      <Grid
                        container
                        component="div"
                        className="apply-form-field"
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            id="outlined-name"
                            label="Name"
                            variant="outlined"
                            className="inner-fields"
                            name="name"
                            value={name}
                            onChange={this.handleChange}
                            inputProps={{
                              maxLength: 100
                            }}
                          />
                          <span className="text-danger">
                            {this.validator.message(
                              "name",
                              name,
                              "required|max:100|alpha_space"
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            id="outlined-name"
                            label="Email"
                            variant="outlined"
                            className="inner-fields"
                            name="email"
                            value={email}
                            onChange={this.handleChange}
                          />
                          <span className="text-danger">
                            {this.validator.message(
                              "email",
                              email,
                              "required|email"
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            id="outlined-name"
                            label="Phone"
                            variant="outlined"
                            className="inner-fields"
                            name="phone"
                            value={phone}
                            onChange={this.handleChange}
                            inputProps={{
                              maxLength: 20
                            }}
                          />
                          <span className="text-danger">
                            {this.validator.message(
                              "phone",
                              phone,
                              "required|max:20|phone"
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            id="outlined-name"
                            label="Subject"
                            variant="outlined"
                            className="inner-fields"
                            fullWidth
                            name="subject"
                            value={subject}
                            onChange={this.handleChange}
                            inputProps={{
                              maxLength: 150
                            }}
                          />
                          <span className="text-danger">
                            {this.validator.message(
                              "subject",
                              subject,
                              "required|max:150"
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="outlined-name"
                            label="Enquiry"
                            variant="outlined"
                            fullWidth
                            className="inner-fields"
                            multiline
                            rows="5"
                            name="enquiry"
                            value={enquiry}
                            onChange={this.handleChange}
                            inputProps={{
                              maxLength: 1000
                            }}
                          />
                          <span className={enquiry.length >= 800 ? "des_count red" : "des_count green"}>{1000 - enquiry.length} characters remaining out of 1000</span>
                          <span className="text-danger">
                            {this.validator.message(
                              "enquiry",
                              enquiry,
                              "required|max:1000"
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <ReCAPTCHA
                            ref={(r) => this.captcha = r}
                            sitekey="6Lcg-cUUAAAAAPReOutbrk0KYH1wZj_bocA4iZZb"
                            onChange={this.handleCaptcha}
                          />
                          <span className="text-danger captcha">
                            {this.validator.message(
                              "captcha",
                              gCaptcha,
                              "required"
                            )}
                          </span>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div className="get-quote-inner-field full button">
                            <FormControl className="form-fields" fullWidth>
                              <input type="submit" value="Submit" title="Submit" />
                              <div className="loader-style-form">
                                {this.state.loader ? <CircularProgress /> : ""}
                              </div>
                            </FormControl>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            {/* End - Section Design "Contact Form" */}

            {/* Start - Section Design "Office Address" */}
            <Grid item xs={12} component="div" className="contact-info-wrap pb-0">
              <Grid item container className="">
                <Grid component="div" className="contact-left-info">
                  <Grid item container className="container_custom">
                    <Typography variant="h3" className="title_footer">
                      Head Office
                    </Typography>
                    <MenuList className="menu_wrap">
                      <MenuItem>
                        <span>
                          <img alt="Location" title="Location" src={location} />
                        </span>
                        <Typography variant="h4">Location</Typography>
                        <Typography>
                          4th Floor, Netsmartz House
                          <br /> Plot No. 10, Rajiv Gandhi Technology Park
                          <br /> Chandigarh - 160101
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <span>
                          <img alt="Call" title="Call" src={call} />
                        </span>
                        <Typography variant="h4">Call Us</Typography>
                        <div className="Multilinks">
                          0172 - <a href="tel:01724540666" title="4540666">4540666</a>,&nbsp;
                          <a href="tel:01724541666" title="4541666">4541666</a>,&nbsp;
                          <a href="tel:01724542666" title="4542666">4542666</a>,&nbsp;
                          <a href="tel:01724543666" title="4543666">4543666</a>
                        </div>
                      </MenuItem>
                      <MenuItem>
                        <span>
                          <img alt="E-mail" title="E-mail" src={mail} />
                        </span>
                        <Typography variant="h4">Mail Us</Typography>
                        <a to="/" href="mailto:sales@visions.net.in" title="sales@visions.net.in">
                          <Typography>sales@visions.net.in</Typography>
                        </a>
                      </MenuItem>
                    </MenuList>
                  </Grid>
                </Grid>
                <Grid component="div" className="contact-right-info">
                  <Grid item container className="container_custom">
                    <Typography variant="h3">Canada Offices</Typography>
                    <MenuList className="menu_wrap">
                      <Typography variant="h4">Vancouver</Typography>
                      <MenuItem>
                        <span>
                          <img alt="User" title="User" src={user} />
                        </span>
                        <Typography>Angad Singh Aujla</Typography>
                      </MenuItem>
                      <MenuItem>
                        <span>
                          <img alt="Call" title="Call" src={call} />
                        </span>
                        <a to="/" href="tel:16047046688" title="+1 (604) 704 6688">
                          <Typography>+1 (604) 704 6688</Typography>
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <span>
                          <img alt="E-mail" title="E-mail" src={mail} />
                        </span>
                        <a to="/" href="mailto:angad@visions.net.in" title="angad@visions.net.in">
                          <Typography>angad@visions.net.in</Typography>
                        </a>
                      </MenuItem>
                    </MenuList>
                    <MenuList className="menu_wrap">
                      <Typography variant="h4">Toronto</Typography>
                      <MenuItem>
                        <span>
                          <img alt="User" title="User" src={user} />
                        </span>
                        <Typography>Vikas Sharma</Typography>
                      </MenuItem>
                      <MenuItem>
                        <span>
                          <img alt="Call" title="Call" src={call} />
                        </span>
                        <a to="/" href="tel:+1 (416) 875 5117" title="+1 (416) 875 5117">
                          <Typography>+1 (416) 875 5117</Typography>
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <span>
                          <img alt="E-mail" title="E-mail" src={mail} />
                        </span>
                        <a to="/" href="mailto:vikas@visions.net.in" title="vikas@visions.net.in">
                          <Typography>vikas@visions.net.in</Typography>
                        </a>
                      </MenuItem>
                    </MenuList>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* End - Section Design "Office Address" */}

            {/* Start - Section Design "Iframe Contact Page" */}
            <Grid item xs={12} component="div" className="contact-map">
              <iframe
                title="contact"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3802.241353062368!2d76.84674343913565!3d30.723293469631585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f9321ad321899%3A0x82e7ec68eb8d631f!2sVisions!5e0!3m2!1sen!2sin!4v1573792456920!5m2!1sen!2sin"
                width="800"
                height="600"
              ></iframe>
            </Grid>
            {/* End - Section Design "Iframe Contact Page" */}

          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default ContactUs;
